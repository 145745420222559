<div style="margin-bottom: 60px;">
  <div class="col-md-12" style="text-align: center;">
    <span class="slide-index">{{ index }} de {{ photos.length }}</span>
    <button (click)="close()" mat-basic-button class="close">
      <mat-icon style="font-size: 34px;color: white;">close</mat-icon>
    </button>
  </div>
  <div>
    <ngx-slick-carousel
      (afterChange)="afterChange($event)"
      class="content-slide"
      #slickCarousel
    >
      <div
        ngxSlickItem
        style="height: 100% !important;"
        *ngFor="let photo of photos; let index = index"
      >
        <div class="col-md-12 caption">
          <span>{{ photo?.image?.caption }}</span>
        </div>
        <div class="col-md-12">
          <img
            *ngIf="photo.docType.id != 1004 && photo.docType.id != 1003"
            [src]="getImage(photo.image.id)"
            class="p-10"
          />
          <a
            style="cursor: pointer;"
            *ngIf="photo.docType.id == 1004 || photo.docType.id == 1003"
            (click)="download(photo)"
          >
            <img src="assets/img/download2.png" class="p-10"
          /></a>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
