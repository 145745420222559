import { Component, OnInit } from "@angular/core";
import { TransactionsService } from "../../services/transactions.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PropertyService } from "../../services/property.services";
import { zip } from "rxjs";
import { DeviceRegistrationService } from "../../../core/services/device-registration.service";
import { AccountService, NewsService } from "../../../core";
import { MatDialog } from "@angular/material/dialog";
import { BreakpointObserver } from "@angular/cdk/layout";
import { ImageGalleryComponent } from "../image-gallery/image-gallery.component";
import { environment } from "../../../../environments/environment";
import { NewsSearch } from "../../models/news-search.model";
import { NewsNavigateService } from "../../services/news.navigate.service";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
import { ProductService } from "../../../core/services/products.service";
import { ProductItem } from "../../../core/models/products.model";
import { I18nService } from "../../services";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexMarkers,
  ApexPlotOptions,
  ApexTheme,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis
} from "ng-apexcharts";
import { title } from "process";
import { DatePipe } from "@angular/common";
import { apexObj } from "../../utils/params";
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  tooltip: ApexTooltip;
  fill: ApexFill;
  colors: string[];
  legend: any; //ApexLegend;
  markers: ApexMarkers;
  plotOptions: ApexPlotOptions;
};

type DataPoint = {
  x: string;
  y: number | null;
  title?: string;
  leasedArea?: number;
};

@Component({
  selector: "app-detail-transactions",
  templateUrl: "./detail-transactions.component.html",
  styleUrls: ["./detail-transactions.component.scss"]
})
export class DetailTransactionsComponent implements OnInit {
  transaction: any;
  id;
  showDetail = false;
  photos: any[] = [];
  registry: any[] = [];
  plan: any[] = [];
  documents: any[] = [];
  isAdmin = false;
  dialogRef: any;
  isMobile: boolean = false;
  listingCards = [];
  articles: NewsSearch;
  browserLang;
  platformId = environment.platformId;
  property;
  marketCardList = [];
  marketImgCard: string[];
  products: ProductItem[];
  currentCode: string;
  typeOpt = 1;
  validateBrokerList = false;
  validateBomaList = false;
  saleCapRate: any;
  public chartOptions: Partial<ChartOptions>;
  typeChar = 1;
  leaseData: any;
  public chartOptionsLease: Partial<ChartOptions>;

  constructor(
    private transactionService: TransactionsService,
    private router: Router,
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private deviceRegistration: DeviceRegistrationService,
    private accountService: AccountService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private newsService: NewsService,
    private newsNavigate: NewsNavigateService,
    private siilaTranslationService: SiilaTranslationService,
    private productService: ProductService,
    private i18: I18nService,
    private datePipe: DatePipe
  ) {
    this.breakpointObserver
      .observe(["(max-width: 450px)"])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    this.currentCode = environment.platformId;
    this.marketImgCard = [
      "assets/img/products-list/indices.png",
      "assets/img/products-list/investors.png",
      "assets/img/products-list/grocs.png"
    ];
    //this.chartOptionsLease = apexObj as ChartOptions;
  }

  ngOnInit() {
    if (this.validateSesion()) {
      this.route.params.subscribe(param => {
        this.id = param.id;
        this.transactionService.getTransactionId(this.id).then(resp => {
          this.transaction = resp;
          this.property = this.transaction.building;
          zip(
            this.propertyService.getPhotosById(this.transaction.building.id),
            this.propertyService.getListingsSpot(this.transaction.building.id),
            this.newsService.getNewsSearch(
              "",
              0,
              5,
              null,
              null,
              null,
              this.transaction.building.id
            ),
            this.transactionService.getSaleCapratesData(
              this.transaction.building.id
            ),
            this.transactionService.getLeaseData(this.transaction.building.id)
          ).subscribe(resp => {
            this.setPhotosType(resp[0]);
            this.listingCards = resp[1] as any;
            this.articles = resp[2] as NewsSearch;
            this.saleCapRate = resp[3];
            this.leaseData = resp[4];
            this.getMarketAnalytics();
            this.validateBrokerList = this.validateBroker();
            this.validateBomaList = this.validateBomaData();
            if (this.transaction?.type == "SALE") {
              this.setCharSale(1);
            }

            if (this.transaction?.type == "LEASE") {
              this.setCharLease(1);
            }
          });
          this.showDetail = true;
          console.log("transaction", resp);
        });
      });
    } else {
      this.router.navigateByUrl("/");
    }
  }

  getMarketAnalytics() {
    this.productService.getMarketAnalytics().subscribe(products => {
      this.products = products;
      this.marketCardList = products.map((item, index) => {
        return {
          id: item.id,
          background: this.marketImgCard[index],
          src: `${environment.cloudfrontURL}${item.iconPath}/360w/${item.iconFilename}`,
          iconAltTitle: this.findIconAltTitle(item),
          title: item.productShortName,
          text: item.menuItemDescription,
          actionText: item.menuItemActionButtonText
        };
      });
    });
  }

  findIconAltTitle = (item: any) => {
    let card = "imgSEO.cardicons.";
    if (item.id == 101) {
      card += "gross";
    } else if (item.id == 102) {
      card += "index";
    } else {
      card += "investorsurvey";
    }
    return this.siilaTranslationService.getTranslationFromJSONFiles(card);
  };

  setPhotosType(photosList) {
    this.photos = photosList.filter(photo => photo.docType.id == 1001);
    this.registry = photosList.filter(registry => registry.docType.id == 1003);
    this.plan = photosList.filter(plan => plan.docType.id == 1002);
    this.documents = photosList.filter(
      documents => documents.docType.id == 1004
    );
  }

  abbreviatedText(text: string): string {
    const value = text ? text.split(" ") : [];
    if (value.length > 1) {
      const firstLetter = value[0].charAt(0);
      const lastLetter = value[value.length - 1].charAt(0);
      return `${firstLetter}${lastLetter}`;
    } else if (value.length === 1) {
      return value[0].charAt(0);
    } else {
      return text;
    }
  }

  async validateSesion() {
    if (localStorage.getItem("token")) {
      return this.setUser();
    } else {
      let isValidToken = this.deviceRegistration.validateCookie();

      if (isValidToken) {
        let account = await this.accountService.getAccount();
        localStorage.setItem("user", JSON.stringify(account));
        return this.setUser();
      }
    }

    return false;
  }

  setUser() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.isAdmin = user?.roles?.includes("ROLE_ADMIN");
    if (this.isAdmin || user?.roles?.includes("ROLE_PROPERTIES")) {
      return true;
    }

    return false;
  }

  downloadReport() {
    this.transactionService.downloadReport(this.id).then((resp: any) => {
      if (resp.success) {
        const fileUrl1 = `${environment.apiURL}/transactionPrints/files/${resp.fileName}`;
        const fileUrl2 = `${environment.apiURL}/transactionPrints/files/${resp.excelFileName}`;

        this.downloadFile(fileUrl1, resp.fileName);
        this.downloadFile(fileUrl2, resp.excelFileName);
      }
    });
  }

  downloadFile(url: string, fileName: string) {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  edit() {
    window.open(
      `${environment.serverURL}/#!/transactions/edit/${this.id}`,
      "blank"
    );
  }

  suggestEdit() {}

  openPhotos(type) {
    this.dialogRef = this.dialog.open(ImageGalleryComponent, {
      height: this.isMobile ? "100%" : "auto",
      width: this.isMobile ? "100%" : "44%",
      maxWidth: "none",
      data: this.validatePhotosType(type),
      panelClass: "custom"
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }

  validatePhotosType(type) {
    switch (type) {
      case 1:
        return this.photos;
        break;
      case 2:
        return this.registry;
        break;
      case 3:
        return this.plan;
        break;
      case 4:
        return this.documents;
        break;
    }
  }

  slickNext(slick) {
    slick.slickNext();
  }

  slickPrev(slick) {
    slick.slickPrev();
  }

  openSpot() {
    window.open(environment.spotURL, "_blank");
  }

  openNews(id: number, title, homeNews = false) {
    if (homeNews) {
      this.router.navigateByUrl("resource");
    } else {
      this.newsNavigate.openNews(
        id,
        title.urlTitle ? title.urlTitle : title.headline,
        this.isMobile,
        "",
        false,
        this.browserLang,
        true,
        null
      );
    }
  }

  validateTrnaslate(text) {
    return this.siilaTranslationService.getTranslation(text);
  }

  goToProperty() {
    window.open(
      `property/${
        this.property.id
      }/lang/${this.siilaTranslationService.getCurrentLanguage()}`
    );
  }

  findBannerImage() {
    var bannerFile;

    if (this.i18.getCurrentLanguage() === "en" || !this.isBR()) {
      bannerFile = "EN_SPOT_BANNER.png";
    } else {
      bannerFile = "PT_SPOT_BANNER.png";
    }

    var fullPath = `../../../../assets/img/property-detail/${bannerFile}`;

    return fullPath;
  }

  findSpotResourceBanner() {
    var bannerFile;

    if (this.isEnglishLang() || !this.isBR()) {
      bannerFile = "EN_REsource_Banner.png";
    } else {
      bannerFile = "PT_REsource_Banner.png";
    }

    var fullPath = `../../../../assets/img/property-detail/${bannerFile}`;
    return fullPath;
  }

  isBR() {
    return this.currentCode && this.currentCode.toLowerCase() === "br";
  }

  openSpotResourceBannerLink() {
    window.open(`resource/lang/${this.i18.getCurrentLanguage()}`);
  }

  isEnglishLang() {
    return this.i18.getCurrentLanguage() === "en";
  }

  openBannerImage() {
    var url;
    if (this.isBR()) {
      url = this.isEnglishLang()
        ? "https://siila.com.br/SPOT/lang/en"
        : "https://siila.com.br/SPOT/lang/pt-br ";
    }
    if (url) {
      window.open(url, this.isMobile ? "_self" : "_blank");
    }
  }

  transactionsPage() {
    window.open(
      `${environment.serverURL}/#!/building/${this.transaction.building.id}/transactions`,
      "blank"
    );
  }

  validateBroker() {
    if (
      this.transaction?.broker1Company ||
      this.transaction?.broker1User ||
      this.transaction.broker1Name ||
      this.transaction.broker1Email ||
      this.transaction?.broker2User ||
      this.transaction?.broker2Company ||
      this.transaction?.broker2Name ||
      this.transaction?.broker2Email
    ) {
      return true;
    }

    return false;
  }

  validateBomaData() {
    if (
      this.transaction?.bomaLeaseArea ||
      this.transaction?.bomaCondo ||
      this.transaction?.bomaTenantImprovements ||
      this.transaction?.bomaLeasingCommission ||
      this.transaction?.bomaLeasingCommission ||
      this.transaction?.bomaOccupancyRate
    ) {
      return true;
    } else return false;
  }

  setCharSale(type) {
    this.typeChar = type;

    this.chartOptions = {
      series: [
        {
          name: "Price vs Date",
          type: "scatter",
          data: this.saleCapRate.map(item => ({
            x: item.transactionDate,
            y: this.typeChar == 1 ? item.price : item.capRate,
            title: this.siilaTranslationService.getTranslation(item.title),
            soldArea: item.soldArea
          }))
        }
      ],
      chart: {
        type: "scatter",
        height: 350
      },
      xaxis: {
        type: "datetime",
        title: {
          text: this.i18.get("transaction.date")
        }
      },
      yaxis: {
        title: {
          text: this.i18.get("transaction.price")
        }
      },
      tooltip: {
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          const point = w.config.series[seriesIndex].data[dataPointIndex];
          const date = point.x;
          const price = point.y.toLocaleString();
          return `
            <div class="apexcharts-tooltip-title">${point.title}</div>
            <div style="margin: 10px">
            <div><strong>${this.i18.get(
              "transaction.date"
            )}:</strong> ${date}</div>
            <div><strong>${
              this.typeChar == 1
                ? this.i18.get("transaction.salePrice")
                : this.i18.get("transaction.capRate")
            }:</strong> $${price}</div>
            <div><strong>${this.i18.get("transaction.soldArea")}:</strong> ${
            point.soldArea
          }</div>
            </div>
          `;
        }
      }
    };

    console.log(this.chartOptions);
  }

  setCharLease(type) {
    this.typeChar = type;
    console.log(this.leaseData.marketRents);
    let data = this.leaseData.leaseTransactions.filter(
      column => column.buildingId == this.transaction.building.id
    );

    const series = [
      {
        name:
          this.siilaTranslationService.getTranslation(
            this.transaction.building.title
          ) +
          " " +
          this.i18.get("transaction.marketRent"),
        type: "bar",
        data: this.leaseData.marketRents.map(column => ({
          x: this.setQuarter(column.dateSurveyed),
          y:
            this.typeChar == 1
              ? column?.marketRent
                ? parseFloat(column.marketRent.toFixed(2))
                : 0
              : column?.marketRentSameRegion
              ? parseFloat(column.marketRentSameRegion.toFixed(2))
              : 0,
          title:
            this.typeChar == 1
              ? this.i18.get("transaction.marketRent")
              : this.i18.get("transaction.marketRentSameRegion")
        }))
      },
      {
        name:
          this.siilaTranslationService.getTranslation(
            this.transaction.building.title
          ) +
          " " +
          this.i18.get("transaction.askingRent"),
        type: "bar",
        data: this.leaseData.marketRents.map(column => ({
          x: this.setQuarter(column.dateSurveyed),
          y:
            this.typeChar == 1
              ? column?.askingRent
                ? parseFloat(column.askingRent.toFixed(2))
                : 0
              : column?.askingRentSameRegion
              ? parseFloat(column.askingRentSameRegion.toFixed(2))
              : 0,
          title:
            this.typeChar == 1
              ? this.i18.get("transaction.askingRent")
              : this.i18.get("transaction.askingRentSameRegion")
        }))
      },
      {
        name:
          this.siilaTranslationService.getTranslation(
            this.transaction.building.title
          ) +
          " " +
          this.i18.get("transaction.leaseTransactions"),
        type: "scatter",
        data: [
          {
            x: this.setQuarter(this.transaction.leaseDate),
            y: this.transaction.monthlyRent / this.transaction.leasedArea,
            title: this.transaction.building.title,
            leasedArea: this.transaction.leasedArea
          }
        ]
      }
    ];

    this.chartOptionsLease = {
      series: series,
      chart: {
        type: "scatter",
        height: 350
      },
      xaxis: {
        type: "datetime",
        title: {
          text: this.i18.get("transaction.date")
        }
      },
      yaxis: {
        title: {
          text: this.i18.get("transaction.rent")
        }
      },
      colors: [
        "#002060",
        "#7f7f7f",
        "#ffff00"
      ] /* ,
      markers: {
        size: 20
      },
      fill: {
        type: "image",
        opacity: 1,
        image: {
          src: [
            "assets/icons/point-stars.png" 
          ],
          width: 40,
          height: 40
        }
      } */,
      tooltip: {
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          const point = w.config.series[seriesIndex].data[dataPointIndex];
          const date = point.x;
          const price = parseFloat(point.y).toLocaleString();
          return `
              <div class="apexcharts-tooltip-title">${this.siilaTranslationService.getTranslation(
                point.title
              )}</div>
              <div style="margin: 10px">
                <div><strong>${this.i18.get(
                  "transaction.date"
                )}:</strong> ${date}</div>
                <div><strong>${this.i18.get(
                  "transaction.value"
                )}:</strong> ${price}</div>
                ${
                  point.leasedArea
                    ? `<div><strong>${this.i18.get(
                        "transaction.leasedAreaChart"
                      )}:</strong> ${point.leasedArea}</div>`
                    : ""
                }
              </div>
            `;
        }
      }
    };

    console.log(this.chartOptionsLease);
  }

  setQuarter(date) {
    let input = new Date(date);
    return `${Math.floor((input.getMonth() + 3) / 3)} ${
      this.browserLang === "pt" ? "T" : "Q"
    } ${this.datePipe.transform(input, "yyyy")}`;
  }
}
