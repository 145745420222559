import { environment } from "../environments/environment";
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ChangeDetectionStrategy,
  ViewChild,
  Renderer2,
  HostListener
} from "@angular/core";
import { enableProdMode } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { isPlatformBrowser, DOCUMENT } from "@angular/common";
import { WindowRef } from "./window-ref.service";
import { ActivatedRouteSnapshot, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs/internal/Observable";
import { AuthenticationService } from "./core/auth/authentication.service";
import { MatomoInjector, MatomoTracker } from "ngx-matomo";
import { DemoDialogComponent } from "./shared/components/dialogs/demo-dialog/demo-dialog.component";
import { ContactDialogComponent } from "./shared/components/dialogs/contact-dialog/contact-dialog.component";
import {
  DemoDialogService,
  SafariPromptDialogService
} from "./core/services/dialog";
import { NotifierService } from "angular-notifier";
import {
  SnackBar,
  SnackBarNotification
} from "./core/services/snack-bar.service";
import { WebsocketService } from "./websockets";
import { SiilaTranslationService } from "./core/services/siila-translation.service";
import { MatSidenav } from "@angular/material/sidenav";
import FontFaceObserver from "fontfaceobserver";
import { ProductService } from "./core/services/products.service";
import { ProductItem, SolutionMenuItem } from "./core/models/products.model";
import { MetaTagService } from "./shared/services/meta-tag.service";
import { AnalyticsService } from "./shared/services/analytics.service";
import { MatDialog } from "@angular/material/dialog";
import { InstallPromptComponent } from "./shared/components";
import { PWAService } from "./shared/services/pwa-service";
import { CaptchaService } from "./shared/services/captcha.service";
import { AccountService } from "./core/auth/account.service";
import { ImageService } from "./core";
import { forkJoin } from "rxjs";
import { DeviceRegistrationService } from "./core/services/device-registration.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["app.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent implements OnInit {
  @HostListener("window:beforeinstallprompt", ["$event"])
  onbeforeinstallprompt(event) {
    event.preventDefault();
    this.pwaService.setDeferredPrompt(event);
  }
  @ViewChild("sidenav") sidenav: MatSidenav;
  public handsetMode: boolean = false;
  public $isLoggedIn: Observable<boolean>;
  cookieAcceptanceName: string = "SIILA-cookie-usage-acceptance";
  cookieAcceptanceAccepted: boolean = true;

  public authenticatedRoutes: any[] = [
    { link: "/", icon: "", text: "navLinks.home" },
    { link: "/listings", icon: "", text: "navLinks.listings" }
  ];

  isDataAvailable: boolean = false;
  addNoIndexNoFollowTag: boolean = false;
  checkId = [];
  products: ProductItem[];
  public solutions: SolutionMenuItem[];
  currentCode: string;
  hideMenu: boolean = false;
  private previousUrl: string = undefined;
  browserLang: string;
  user;
  photoUser = "";

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: any,
    public router: Router,
    private windowRef: WindowRef,
    private swUpdate: SwUpdate,
    private breakpointObserver: BreakpointObserver,
    private auth: AuthenticationService,
    private matomoInjector: MatomoInjector,
    private demo: DemoDialogService,
    private _safariPrompt: SafariPromptDialogService,
    private _notifier: NotifierService,
    private websocketService: WebsocketService,
    private matomoTracker: MatomoTracker,
    private siilaTranslationService: SiilaTranslationService,
    private renderer: Renderer2,
    private productService: ProductService,
    private analyticsService: AnalyticsService,
    private metaTagSrv: MetaTagService,
    private pwaService: PWAService,
    private accountService: AccountService,
    private imageService: ImageService,
    private deviceRegistration: DeviceRegistrationService
  ) {
    this.currentCode = environment.platformId;
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    const materialIcons = new FontFaceObserver("Material Icons");
    materialIcons
      .load(null, 10000)
      .then(() =>
        this.renderer.addClass(document.body, `material-icons-loaded`)
      );

    if (
      this.windowRef.nativeWindow.location.pathname.includes("insight-preview")
    ) {
      this.hideMenu = true;
    } else {
      this.hideMenu = false;
    }

    if (isPlatformBrowser(this.platformId)) {
      switch (this.windowRef.nativeWindow.location.host) {
        case "stg.siila.com.br":
        case "www.stg.siila.com.br":
        case "test.siila.com.br":
        case "www.test.siila.com.br":
        case "www.dev.siila.com.br":
        case "dev.siila.com.br":
          this.initMatomoTracker(3);
          break;
        case "siila.com.br":
        case "www.siila.com.br":
          this.initMatomoTracker(2);
          break;
        case "stg.siila.com.mx":
        case "www.stg.siila.com.mx":
        case "test.siila.com.mx":
        case "www.test.siila.com.mx":
        case "www.dev.siila.com.mx":
        case "dev.siila.com.mx":
        case "localhost:4200":
        case "localhost":
          this.initMatomoTrackerForMX(3);
          break;
        case "siila.com.mx":
        case "www.siila.com.mx":
          this.initMatomoTrackerForMX(2);
          break;
        case "siila.com":
        case "www.siila.com":
          this.initMatomoTrackerLatam(4);
          break;
      }
      this.deviceUsingSafari();
    }
  }

  public async validateUpdate() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(evt => {
        /* if (localStorage.getItem("token")) {
          this.auth.removeCredentials();
        } */
        if (sessionStorage.getItem("isDataAvailable") == null) {
          this.isDataAvailable = true;
          sessionStorage.setItem("isDataAvailable", "true");
          this.windowRef.nativeWindow.location.reload();
        }
      });
    }

    this.swUpdate
      .checkForUpdate()
      .then(() => {
        this.isDataAvailable = true;
      })
      .catch(err => {
        this.isDataAvailable = true;
        console.error("error when checking for update", err);
      });
  }

  public async ngOnInit() {
    this.loadGoogleMapsApi();
    this.validateNoIndexNoFollowTag();
    (window as any).prerenderReady = false;
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        const snapshot: ActivatedRouteSnapshot = this.router.routerState
          .snapshot.root.firstChild;
        (window as any).prerenderReady = false;
        const eventP: any = event;
        if (eventP) {
          let url: string = eventP.url;
          const lang = this.siilaTranslationService.getLanguageBasedOnURL(url);

          if (
            lang &&
            lang !== this.siilaTranslationService.getCurrentLanguage()
          ) {
            this.siilaTranslationService.setLanguage(lang, true);
          }

          url = this.siilaTranslationService.removeLanguageFromURL(url);
          let dynamicPage = false;
          if (url) {
            switch (url) {
              case "/":
              case "":
                this.metaTagSrv.setHomePageMetaTags();
                break;
              case "/SPOT":
                this.metaTagSrv.setSpotPageMetaTags();
                break;
              case "/products":
                this.metaTagSrv.setAllProductsMetaTags();
                break;
              case "/marketanalytics":
                this.metaTagSrv.setMarketAnalyticsMetaTags();
                break;
              case "/academy":
                this.metaTagSrv.setAcademyMetaTags();
                break;
              case "/fibra-analytics":
              case "/fii-data-pro":
                this.metaTagSrv.setFibraAnalyticsMetaTags();
                break;
              case "/about":
                this.metaTagSrv.setAboutMetaTags();
                break;
              default:
                dynamicPage = true;
                break;
            }
            if (dynamicPage) {
              if (url.endsWith("investor-survey")) {
                this.metaTagSrv.setInvestorSurveyTags();
              } else if (url.endsWith("grocs")) {
                this.metaTagSrv.setGrocsTags();
              } else if (url.endsWith("grocs")) {
                this.metaTagSrv.setGrocsTags();
              } else if (url.endsWith("índices")) {
                this.metaTagSrv.setIndicesTags();
              } else if (
                url.endsWith("/101/investidores") ||
                url.endsWith("/108/inversionistas-institucionales") ||
                url.endsWith("/108/institutional-investors")
              ) {
                this.metaTagSrv.setInstitutionalInvestors();
              } else if (
                url.startsWith("/solutions/103/") ||
                url.startsWith("/solutions/110/")
              ) {
                this.metaTagSrv.setOwnersOperatorDeveloperTags();
              } else if (
                url.startsWith("/solutions/104/") ||
                url.startsWith("/solutions/111/")
              ) {
                this.metaTagSrv.setTenantsTags();
              } else if (
                url.startsWith("/solutions/102/") ||
                url.startsWith("/solutions/109/")
              ) {
                this.metaTagSrv.setTenantsTags();
              }
            }

            if (dynamicPage) {
              if (
                url.startsWith("/resource") ||
                url.startsWith("/resource/tags") ||
                url.startsWith("/search_news")
              ) {
                this.metaTagSrv.setInsightsHomeTags();
              }
            }

            if (dynamicPage) {
              if (url.startsWith("/press")) {
                this.metaTagSrv.setPressHomeTags();
              }
            }

            this.siilaTranslationService.addCurrentLanguageToPath(url);
          }

          this.metaTagSrv.addCanonicalTag(window.location.href);

          if (!this.previousUrl != undefined && this.previousUrl != null) {
            this.trackURLMatomo(url);
          }

          this.previousUrl = eventP.url;
        }

        setTimeout(() => {
          (window as any).prerenderReady = true;
        }, 2500);

        this._notifier.notify("error", "Update available reloading app.");
        this.breakpointObserver
          .observe([Breakpoints.Handset])
          .subscribe(result => {
            this.handsetMode = result.matches;
          });
      });

    this.productService.getMarketAnalytics().subscribe(products => {
      this.products = products;
      this.checkId = products.map(item => {
        //console.log(item);
        return {
          id: item.id,
          title: item.productShortName,
          icon: item.iconCode
        };
      });
    });

    this.productService.getSolutionList().subscribe(solutions => {
      this.solutions = solutions;
    });

    this.validateUpdate();

    setTimeout(() => {
      if (!this.isDataAvailable) {
        this.isDataAvailable = true;
      }
      sessionStorage.setItem("isDataAvailable", "true");
      this.analyticsService.init();
    }, 5000);

    this.checkCookiesUsageAcceptance();

    if (environment.production) {
      enableProdMode();
    }

    const guestTracker = new (window as any).Postmate({
      container: document.getElementById("postmateHub"), //index.html
      url: "https://d3s7dhq6uqmajv.cloudfront.net/index.html",
      name: "guest-tracking-client"
    });

    const murmur = await guestTracker.then(async child => {
      const guestMurmur = await child.get("guestMurmur").then(guestMurmur => {
        if (guestMurmur) {
          localStorage.setItem(
            "client-guest-murmur",
            JSON.stringify(guestMurmur)
          );
        }
        setTimeout(() => {
          child.destroy();
        }, 5000);
        return guestMurmur;
      });
      return guestMurmur;
    });

    this.$isLoggedIn = this.auth.isLoggedIn();

    if (environment.platformId == "br" || environment.platformId == "mx") {
      this.validateCookie();
    }

    if (murmur) {
      this.websocketService.connect();
    }

    /*
      Defers Add to homescreen prompt
    */
    window.addEventListener("beforeInstallPrompt", event => {
      event.preventDefault();
    });

    //this.newVersionService.open(NewVersionComponent);
  }

  loadGoogleMapsApi(): void {
    if (
      !document.querySelector(
        `script[src="https://maps.googleapis.com/maps/api/js?key=${environment.googleAPIKey}"]`
      )
    ) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleAPIKey}`;
      script.async = true;
      document.body.appendChild(script);
    }
  }

  private trackURLMatomo(url: string) {
    this.accountService.getUserIPNoPromise().subscribe(resp => {
      const piwikTrackingUrl =
        this.router["location"]._platformLocation.location.origin +
        this.router.url;
      let ipObj: any = resp;
      let user = JSON.parse(localStorage.getItem("user"));
      let userId = "ANONYMOUS " + ipObj.ip;
      if (user && user.email) {
        userId = user.email + " " + ipObj.ip;
      }
      this.matomoTracker.setUserId(userId);
      this.matomoTracker.setCustomUrl(piwikTrackingUrl);
      console.log("printing url: " + url);
      this.matomoTracker.trackPageView(url);
      this.matomoTracker.setReferrerUrl(piwikTrackingUrl);
    });
  }

  validateCookie() {
    let isValidToken = this.deviceRegistration.validateCookie();

    if (isValidToken)
      forkJoin([
        this.accountService.getAccount(),
        this.accountService.getUserPhoto()
      ]).subscribe(
        ([account, photos]) => {
          localStorage.setItem("user", JSON.stringify(account));
          this.user = account;
          this.photoUser = this.imageService.mediumLargeThumbnail(photos[1]);
          localStorage.setItem("photoUser", this.photoUser);
          this.auth.$isLoginSubject.next(true);
          console.log("init1");
        },
        error => {
          console.error("Error", error);
        }
      );
  }

  checkCookiesUsageAcceptance() {
    let cookieAcceptance: boolean = false;
    try {
      cookieAcceptance = !!window.localStorage.getItem(
        this.cookieAcceptanceName
      );
    } catch (error) {
      console.error("No localStorage available");
    }

    if (!cookieAcceptance) {
      setTimeout(() => (this.cookieAcceptanceAccepted = false), 1000);
    }
  }

  acceptCookieUsage() {
    this.cookieAcceptanceAccepted = true;
    try {
      localStorage.setItem(this.cookieAcceptanceName, "Accepted");
    } catch (error) {
      console.error("No localStorage available");
    }
  }

  deviceUsingSafari() {
    const isUsingSafari =
      this._safariPrompt.browserIsSafari() &&
      !this._safariPrompt.isStandaloneLaunch();

    if (isUsingSafari) {
      //this._safariPrompt.openDialog();
    }

    return isUsingSafari;
  }

  openDemoDialog() {
    this.demo.open(DemoDialogComponent);
  }

  public login() {
    this.windowRef.nativeWindow.open(`${environment.redirectURL}`, "_self");
  }

  logout() {
    this.auth.logout();
  }

  goTo(e: Event, url: string) {
    e.preventDefault();
    if (url == "/blog") {
      window.open(url, "_blank");
    } else {
      this.router.navigateByUrl(url);
    }
    this.sidenav.toggle();
  }

  initMatomoTracker(env: number) {
    console.log("Initilizing Matomo Tracker BR");
    if (isPlatformBrowser(this.platformId)) {
      console.log("Initilized Matomo Tracker BR");
      let currentUrl = this.router.url;
      currentUrl = this.siilaTranslationService.removeLanguageFromURL(
        currentUrl
      );
      this.accountService.getUserIPNoPromise().subscribe(resp => {
        const matomo = this.matomoInjector.init(
          "//piwik.siila.com.br/",
          env || 3
        );
        const piwikTrackingUrl =
          this.router["location"]._platformLocation.location.origin +
          this.router.url;
        let ipObj: any = resp;
        let user = JSON.parse(localStorage.getItem("user"));
        let userId = "ANONYMOUS " + ipObj.ip;
        if (user && user.email) {
          userId = user.email + " " + ipObj.ip;
        }
        this.matomoTracker.setUserId(userId);
        this.matomoTracker.setCustomUrl(piwikTrackingUrl);
        this.matomoTracker.setReferrerUrl(piwikTrackingUrl);
      });
    }
  }

  initMatomoTrackerLatam(env: number) {
    console.log("Initilizing Matomo Tracker LATAM");
    if (isPlatformBrowser(this.platformId)) {
      console.log("Initilized Matomo Tracker LATAM");
      let currentUrl = this.router.url;
      currentUrl = this.siilaTranslationService.removeLanguageFromURL(
        currentUrl
      );
      this.accountService.getUserIPNoPromise().subscribe(resp => {
        const matomo = this.matomoInjector.init(
          "//piwik.siila.com.mx/",
          env || 4
        );
        const piwikTrackingUrl =
          this.router["location"]._platformLocation.location.origin +
          this.router.url;
        let ipObj: any = resp;
        let user = JSON.parse(localStorage.getItem("user"));
        let userId = "ANONYMOUS " + ipObj.ip;
        if (user && user.email) {
          userId = user.email + " " + ipObj.ip;
        }
        this.matomoTracker.setUserId(userId);
        this.matomoTracker.setCustomUrl(piwikTrackingUrl);
        this.matomoTracker.setReferrerUrl(piwikTrackingUrl);
      });
    }
  }

  initMatomoTrackerForMX(env: number) {
    console.log("Initilizing Matomo Tracker MX");
    if (isPlatformBrowser(this.platformId)) {
      console.log("Initilized Matomo Tracker MX");
      let currentUrl = this.router.url;
      currentUrl = this.siilaTranslationService.removeLanguageFromURL(
        currentUrl
      );
      this.accountService.getUserIPNoPromise().subscribe(resp => {
        const matomo = this.matomoInjector.init(
          "//piwik.siila.com.mx/",
          env || 3
        );
        const piwikTrackingUrl =
          this.router["location"]._platformLocation.location.origin +
          this.router.url;
        let ipObj: any = resp;
        let user = JSON.parse(localStorage.getItem("user"));
        let userId = "ANONYMOUS " + ipObj.ip;
        if (user && user.email) {
          userId = user.email + " " + ipObj.ip;
        }
        this.matomoTracker.setUserId(userId);
        this.matomoTracker.setCustomUrl(piwikTrackingUrl);
        this.matomoTracker.setReferrerUrl(piwikTrackingUrl);
      });
    }
  }

  openEmail() {
    if (this.currentCode == "br") {
      window.location.href = `mailto:contato@siila.com.br`;
    } else {
      if (this.currentCode == "co") {
        window.location.href = `mailto:contacto@siila.com.co
        `;
      } else {
        window.location.href = `mailto:contacto@siila.com.mx`;
      }
    }
    return false;
  }

  openMedia() {
    if (this.currentCode == "mx" || this.currentCode == "latam") {
      window.open(
        `https://www.youtube.com/channel/UCTm4KXeOEGPEyCNyCHH_Jgw`,
        this.handsetMode ? "_self" : "_blank"
      );
    } else {
      window.open(
        `https://www.youtube.com/c/SiiLABR`,
        this.handsetMode ? "_self" : "_blank"
      );
    }
  }

  openPress() {
    window.open(
      `https://siila.com/press/lang/en`,
      this.handsetMode ? "_self" : "_blank"
    );
  }

  validateNoIndexNoFollowTag() {
    switch (this.windowRef.nativeWindow.location.host) {
      //case "stg.siila.com.br":
      //case "www.stg.siila.com.br":
      case "test.siila.com.br":
      case "www.test.siila.com.br":
      case "www.dev.siila.com.br":
      case "dev.siila.com.br":
        this.addNoIndexMetaTag();
        break;
      case "siila.com.br":
      case "www.siila.com.br":
        break;
      case "stg.siila.com.mx":
      case "www.stg.siila.com.mx":
      case "test.siila.com.mx":
      case "www.test.siila.com.mx":
      case "www.dev.siila.com.mx":
      case "dev.siila.com.mx":
      case "localhost:4200":
      case "localhost":
        this.addNoIndexMetaTag();
        break;
      case "siila.com.mx":
      case "siila.com":
      case "www.siila.com":
      case "www.siila.com.mx":
        break;
    }
  }

  addNoIndexMetaTag() {
    const metaTag: HTMLMetaElement = document.createElement("meta");
    metaTag.setAttribute("name", "robots");
    metaTag.setAttribute("content", "noindex, nofollow");
    document.head.appendChild(metaTag);
  }

  navigateToAbout() {
    this.router.navigate(["/about"], { fragment: "leadership" });
  }

  openResource(country) {
    if (country == "mx") {
      window.open(
        `${environment.applicationURLMx}/resource/lang/${this.browserLang}`,
        this.handsetMode ? "_self" : "_blank"
      );
    } else {
      window.open(
        `${environment.applicationURLBr}/resource/lang/en`,
        this.handsetMode ? "_self" : "_blank"
      );
    }
  }
}
