import { color } from "html2canvas/dist/types/css/types/color";

export const page = {
  products: "products",
  analitycs: "analytics",
  spot: "SPOT",
  academy: "academy",
  fiiData: "fii-data",
  about: "about"
};

export const action = {
  learnMore: "learn-more",
  requestDemo: "request-demo",
  subscribe: "subscribe-now",
  moreInfo: "more-info",
  startNow: "start-now",
  contactUs: "contact-us",
  touchs: "touchs"
};

export const captchaImg = {
  images: [
    { img: "assets/img/captcha.png", text: "xajfi" },
    { img: "assets/img/captcha1.png", text: "uejvh" },
    { img: "assets/img/captcha2.png", text: "fnjiq" },
    { img: "assets/img/captcha3.png", text: "fbsvfq" }
  ]
};

export const latLog = 0.0000000001;

export const kmlSourceBr = "https://s3-sa-east-1.amazonaws.com/siila-br-kml/";
export const kmlSourceMx = "https://s3-sa-east-1.amazonaws.com/siila-mx-kml/";

export const kmlCommercial = [{ kmlUrl: kmlSourceBr + "grocs.kml" }];

export const kmlIndustrial = [
  { kmlUrl: kmlSourceBr + "industiralSOPAULO.kmz" },
  { kmlUrl: kmlSourceBr + "industrialDEJANEIRO.kmz" },
  { kmlUrl: kmlSourceBr + "industrialMINASGERAIS.kmz" },
  { kmlUrl: kmlSourceBr + "industrialAMAZONAS.kmz" },
  { kmlUrl: kmlSourceBr + "industrialBAHIA.kmz" },
  { kmlUrl: kmlSourceBr + "industrialCEAR.kmz" },
  { kmlUrl: kmlSourceBr + "industrialDISTRITOFEDERAL.kmz" },
  { kmlUrl: kmlSourceBr + "industrialESPRITOSANTO.kmz" },
  { kmlUrl: kmlSourceBr + "industrialGOI.kmz" },
  { kmlUrl: kmlSourceBr + "industrialPARAN.kmz" },
  { kmlUrl: kmlSourceBr + "industrialSANTACATARINA.kmz" },
  { kmlUrl: kmlSourceBr + "industrialGRANDEDOSUL.kmz" },
  { kmlUrl: kmlSourceBr + "industrialPARABA.kmz" },
  { kmlUrl: kmlSourceBr + "industrialSERGIPE.kmz" }
];

export const kmlOffice = [
  { kmlUrl: kmlSourceBr + "officeCAMPINAS.kmz" },
  { kmlUrl: kmlSourceBr + "officePORTOALEGRE.kmz" },
  { kmlUrl: kmlSourceBr + "officeCURITIBA.kmz" },
  { kmlUrl: kmlSourceBr + "officeSAOPAULO_v1.kmz" },
  { kmlUrl: kmlSourceBr + "officeRIODEJANEIRO.kmz" },
  { kmlUrl: kmlSourceBr + "officeBELOHORIZONTE.kmz" }
];

export const apexObj = {
  series: [],
  chart: {
    height: 350,
    type: "line",
    toolbar: {
      show: true,
      tools: {
        download: true
      }
    }
  },
  stroke: {
    curve: "smooth",
    width: 2
  },
  title: {
    text: ""
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [1]
  },
  labels: [],
  xaxis: {
    type: "datetime"
  },
  yaxis: [
    {
      title: {
        text: "Market Rent R$"
      }
    },
    {
      opposite: true,
      min: 0,
      max: 100,
      title: {
        text: "Occupancy %"
      }
    }
  ]
};
