<div class="nav-container">
  <div class="col-md-12 color-span nav-detail">
    <div class="col-md-3 f-left mt-5">
      {{
        transaction?.type == "SALE"
          ? ("transaction.sale" | translate)
          : ("transaction.lease" | translate)
      }}
      {{ "transaction.transactions" | translate }}
      {{ transaction?.building?.buildingType?.name | langSelector }}
    </div>
    <div class="col-md-6 title-row  f-left mt-1">
      <span class="title">
        {{ transaction?.building?.title | langSelector }}
      </span>
      <br />
      <span>
        {{ transaction?.building?.address | langSelector }},
        {{ transaction?.building?.city?.name | langSelector }},
        {{
          abbreviatedText(
            transaction?.building?.city?.countryState?.name | langSelector
          )
        }}
      </span>
    </div>
    <div class="col-md-3 f-left mt-5" style="text-align: end;">
      {{ transaction?.building?.region?.name | langSelector }}
    </div>
  </div>
</div>
<div class="content">
  <app-loading-spinner *ngIf="!showDetail"></app-loading-spinner>
  <div *ngIf="showDetail" class="content-detail">
    <div class="w-100">
      <app-show-images
        [photos]="photos"
        [latitude]="transaction?.building?.latitude"
        [longitude]="transaction?.building?.longitude"
        [buildingTypeId]="transaction?.building?.buildingType.id"
        class="d-flex pl"
      ></app-show-images>
    </div>
    <div class="col-md-12 content-option mt-3" [ngClass]="{ 'p-0': isMobile }">
      <div class="content-option-btn col-md-8">
        <div *ngIf="transaction?.building?.buildingType.id != 9001">
          <img
            style="width: 20px;
          margin-right: 5px;"
            src="assets/featuresIcons/generate-reports.png"
            alt=""
          />
          <span (click)="downloadReport()">{{
            "detail.generateReport" | translate
          }}</span>
        </div>
        <div (click)="isAdmin ? edit() : suggestEdit()">
          <mat-icon style="margin-right: 6px;">edit</mat-icon>
          <span>{{
            isAdmin
              ? ("detail.edit" | translate)
              : ("detail.suggestEdit" | translate)
          }}</span>
        </div>
        <div>
          <img
            style="width: 20px;
        margin-right: 5px;"
            src="assets/featuresIcons/analyze-2.png"
            alt=""
          /><span (click)="transactionsPage()">{{
            "transaction.viewTransactions" | translate
          }}</span>
        </div>
      </div>
      <div class="content-option-btn col-md-4">
        <div
          style="border: none;
            border-right: 1px solid gray;
            border-radius: inherit;margin-left: -40px;"
        ></div>
        <div *ngIf="registry.length > 0">
          <span (click)="openPhotos(2)">{{
            "detail.matricula" | translate
          }}</span>
        </div>
        <div *ngIf="plan.length > 0">
          <span (click)="openPhotos(3)">{{ "detail.plans" | translate }}</span>
        </div>
        <div *ngIf="documents.length > 0">
          <span (click)="openPhotos(4)">{{
            "detail.otherDocs" | translate
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-2" [ngClass]="{ 'p-0': isMobile }">
      <div style="border-bottom: 1px solid #00334d;margin: 22px;"></div>
    </div>
    <div class="col-md-12 mt-5 content-columns">
      <div class="col-md-8" [ngClass]="{ 'p-0': isMobile }">
        <div
          class="col-md-12 p-r"
          *ngIf="
            transaction?.salesNotes &&
            validateTrnaslate(transaction?.salesNotes) != ''
          "
        >
          <div class="col-md-12 key-facts ">
            <span>{{ "transaction.saleDescription" | translate }}</span>
          </div>

          <div class="mt-2" style="margin: 0;text-align: justify;">
            <span class="mt-2">{{
              transaction?.salesNotes | langSelector
            }}</span>
          </div>
        </div>
        <div
          class="col-md-12 p-r"
          *ngIf="
            transaction?.leaseNotes &&
            validateTrnaslate(transaction?.leaseNotes) != ''
          "
        >
          <div class="col-md-12 key-facts ">
            <span>{{ "transaction.leaseDescription" | translate }}</span>
          </div>

          <div class="mt-2" style="margin: 0;text-align: justify;">
            <span class="mt-2">{{
              transaction?.leaseNotes | langSelector
            }}</span>
          </div>
        </div>
        <div class="key-columns">
          <div class="col-md-6">
            <div class="col-md-12 key-facts mt-2">
              <span
                >{{
                  transaction?.type == "SALE"
                    ? ("transaction.sale" | translate)
                    : ("transaction.lease" | translate)
                }}
                {{ "detail.keyFacts" | translate }}</span
              >
            </div>
            <div class="d-flex key-facts-columns border-table">
              <div class="column label-color">
                {{ "transaction.property" | translate }}
              </div>
              <div class="column label-color2">
                <a class="link" (click)="goToProperty()">{{
                  property?.title | langSelector
                }}</a>
              </div>
              <div class="column label-color" *ngIf="transaction?.buyers">
                {{ "transaction.buyer" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.buyers">
                {{ transaction?.buyers | langSelector }}
              </div>
              <div class="column label-color" *ngIf="transaction?.sellers">
                {{ "transaction.seller" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.sellers">
                {{ transaction?.sellers | langSelector }}
              </div>
              <div class="column label-color" *ngIf="transaction?.tenants">
                {{ "transaction.tenant" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.tenants">
                {{ transaction?.tenants | langSelector }}
              </div>
              <div class="column label-color" *ngIf="transaction?.owner">
                {{ "transaction.owner" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.owner">
                {{ transaction?.owner | langSelector }}
              </div>
              <div class="column label-color" *ngIf="transaction?.floor">
                {{ "transaction.unit" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.floor">
                {{ transaction?.floor }}
              </div>
              <div class="column label-color" *ngIf="transaction?.saleDate">
                {{ "transaction.saleDate" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.saleDate">
                {{ transaction?.saleDate }}
              </div>
              <div class="column label-color" *ngIf="transaction?.salePrice">
                {{ "transaction.totalPrice" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.salePrice">
                {{ transaction?.salePrice | localNumber }}
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.percentInterestSold"
              >
                {{ "transaction.percentInterestSold" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.percentInterestSold"
              >
                {{ transaction?.percentInterestSold | localNumber }}%
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.occupancyAtSale"
              >
                {{ "transaction.occupancyAtSale" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.occupancyAtSale"
              >
                {{ transaction?.occupancyAtSale | localNumber }} %
              </div>
              <div
                *ngIf="
                  transaction?.saleAndLeaseback ||
                  transaction?.saleAndLeaseback == false
                "
                class="column label-color"
              >
                {{ "transaction.saleAndLeaseback" | translate }}
              </div>
              <div
                *ngIf="
                  transaction?.saleAndLeaseback ||
                  transaction?.saleAndLeaseback == false
                "
                class="column label-color2"
              >
                {{
                  transaction?.saleAndLeaseback
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                *ngIf="transaction?.bts || transaction?.bts == false"
                class="column label-color"
              >
                {{ "transaction.bts" | translate }}
              </div>
              <div
                *ngIf="transaction?.bts || transaction?.bts == false"
                class="column label-color2"
              >
                {{
                  transaction?.bts
                    ? ("detail.yes" | translate)
                    : ("detail.no" | translate)
                }}
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.moduleEfficiency"
              >
                {{ "transaction.moduleEfficiency" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.moduleEfficiency"
              >
                {{ transaction?.moduleEfficiency }}
              </div>
              <div class="column label-color" *ngIf="transaction?.floorLoad">
                {{ "transaction.floorLoad" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.floorLoad">
                {{ transaction?.floorLoad }}
              </div>
              <div class="column label-color" *ngIf="transaction?.leaseTerm">
                {{ "transaction.leaseTerm" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.leaseTerm">
                {{ transaction?.leaseTerm }}
              </div>
              <div class="column label-color" *ngIf="transaction?.leaseDate">
                {{ "transaction.leaseDate" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.leaseDate">
                {{ transaction?.leaseDate }}
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.maturityDateContract"
              >
                {{ "transaction.leaseMaturityDate" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.maturityDateContract"
              >
                {{ transaction?.maturityDateContract }}
              </div>

              <div class="column label-color" *ngIf="transaction?.monthlyRent">
                {{ "transaction.monthlyRent" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.monthlyRent">
                {{ transaction?.monthlyRent | localNumber }}
              </div>
              <div class="column label-color" *ngIf="transaction?.escalations">
                {{ "transaction.formattedEsc" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.escalations">
                {{ transaction?.escalations | langSelector }}
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.freeRentMonths"
              >
                {{ "transaction.freeRentMonths" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.freeRentMonths"
              >
                {{ transaction?.freeRentMonths }}
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.occupancyAtLease"
              >
                {{ "transaction.occupancyAtLease" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.occupancyAtLease"
              >
                {{ transaction?.occupancyAtLease | langSelector }}%
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="col-md-12 key-facts mt-2">
              <span>{{ "detail.areasMeasurements" | translate }}</span>
            </div>
            <div class="d-flex key-facts-columns border-table">
              <div class="col-md-12 p-0" *ngIf="validateBomaList">
                <mat-button-toggle-group
                  class="toggle-group w-100 toggle-options"
                  appearance="legacy"
                  name="fontStyle"
                  aria-label="Font Style"
                >
                  <mat-button-toggle
                    [ngClass]="{ 'active-toggle': typeOpt === 1 }"
                    (click)="typeOpt = 1"
                    class="toogle-btn"
                  >
                    {{ "transaction.private" | translate }}</mat-button-toggle
                  >
                  <mat-button-toggle
                    [ngClass]="{ 'active-toggle': typeOpt === 2 }"
                    (click)="typeOpt = 2"
                    class="toogle-btn"
                  >
                    {{ "transaction.boma" | translate }}</mat-button-toggle
                  >
                </mat-button-toggle-group>
              </div>
              <div
                class="column label-color flexB-60"
                *ngIf="transaction?.leasedArea && typeOpt === 1"
              >
                {{ "transaction.leasedArea" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.leasedArea && typeOpt === 1"
              >
                {{ transaction?.leasedArea | localNumber }}
              </div>
              <div
                class="column label-color flexB-60"
                *ngIf="transaction?.monthlyRentPerArea && typeOpt === 1"
              >
                {{ "transaction.monthlyRentPerArea" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.monthlyRentPerArea && typeOpt === 1"
              >
                {{ transaction?.monthlyRentPerArea | localNumber }}
              </div>
              <div
                class="column label-color flexB-60"
                *ngIf="transaction?.condo && typeOpt === 1"
              >
                {{ "transaction.monthlyCondoFees" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.condo && typeOpt === 1"
              >
                {{ transaction?.condo | localNumber }}
              </div>
              <div
                class="column label-color flexB-60"
                *ngIf="transaction?.tenantImprovements && typeOpt === 1"
              >
                {{ "transaction.tenantImprovements" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.tenantImprovements && typeOpt === 1"
              >
                {{ transaction?.tenantImprovements | localNumber }}
              </div>
              <div
                class="column label-color flexB-60"
                *ngIf="transaction?.leasingCommission && typeOpt === 1"
              >
                {{ "transaction.leasingCommission" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.leasingCommission && typeOpt === 1"
              >
                {{ transaction?.leasingCommission | localNumber }}
              </div>
              <div
                class="column label-color flexB-60"
                *ngIf="transaction?.bomaLeaseArea && typeOpt === 2"
              >
                {{ "transaction.bomaLeaseArea" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.bomaLeaseArea && typeOpt === 2"
              >
                {{ transaction?.bomaLeaseArea | localNumber }}
              </div>
              <div
                class="column label-color flexB-60"
                *ngIf="
                  transaction?.bomaLeaseArea != null &&
                  transaction?.bomaLeaseArea != 0 &&
                  typeOpt === 2
                "
              >
                {{ "transaction.bomaMonthlyRent" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  transaction?.bomaLeaseArea != null &&
                  transaction?.bomaLeaseArea != 0 &&
                  typeOpt === 2
                "
              >
                {{
                  transaction?.monthlyRent / transaction?.bomaLeaseArea
                    | localNumber
                }}
              </div>
              <div
                class="column label-color flexB-60"
                *ngIf="transaction?.bomaCondo && typeOpt === 2"
              >
                {{ "transaction.bomaCondo" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.bomaCondo && typeOpt === 2"
              >
                {{ transaction?.bomaCondo | localNumber }}
              </div>
              <div
                class="column label-color flexB-60"
                *ngIf="transaction?.bomaTenantImprovements && typeOpt === 2"
              >
                {{ "transaction.bomaTenantImprovements" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.bomaTenantImprovements && typeOpt === 2"
              >
                {{ transaction?.bomaTenantImprovements | localNumber }}
              </div>

              <div
                class="column label-color flexB-60"
                *ngIf="transaction?.bomaLeasingCommission && typeOpt === 2"
              >
                {{ "transaction.bomaLeasingCommission" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.bomaLeasingCommission && typeOpt === 2"
              >
                {{ transaction?.bomaLeasingCommission | localNumber }}
              </div>
              <div
                class="column label-color flexB-60"
                *ngIf="transaction?.bomaOccupancyRate && typeOpt === 2"
              >
                {{ "transaction.bomaOccupancyRate" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.bomaOccupancyRate && typeOpt === 2"
              >
                {{ transaction?.bomaOccupancyRate | localNumber }}%
              </div>
              <div
                class="column label-color flexB-60"
                *ngIf="transaction?.occupancyRate && typeOpt === 1"
              >
                {{ "transaction.occupancyRate" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.occupancyRate && typeOpt === 1"
              >
                {{ transaction?.occupancyRate | localNumber }} %
              </div>
              <div
                class="column label-color flexB-60"
                *ngIf="transaction?.moduleEfficiency && typeOpt === 1"
              >
                {{ "transaction.moduleEfficiency" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.moduleEfficiency && typeOpt === 1"
              >
                {{ transaction?.moduleEfficiency }}
              </div>
              <div
                class="column label-color flexB-60"
                *ngIf="transaction?.floorLoad && typeOpt === 1"
              >
                {{ "transaction.floorLoad" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.floorLoad && typeOpt === 1"
              >
                {{ transaction?.floorLoad }}
              </div>

              <!-- <div
                class="column label-color flexB-60"
                *ngIf="transaction?.occupancyDateLastSurveyed && typeOpt === 1"
              >
                {{ "transaction.occupancyLastSurveyed" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.occupancyDateLastSurveyed && typeOpt === 1"
              >
                {{ transaction?.occupancyDateLastSurveyed }}
              </div> -->
              <div
                class="column label-color flexB-60"
                *ngIf="transaction?.soldArea && typeOpt === 1"
              >
                {{ "transaction.privateSoldArea" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.soldArea && typeOpt === 1"
              >
                {{ transaction?.soldArea | localNumber }}
              </div>
              <div
                class="column label-color flexB-60"
                *ngIf="transaction?.sellPricePerArea && typeOpt === 1"
              >
                {{ "transaction.privatePrice" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.sellPricePerArea && typeOpt === 1"
              >
                {{ transaction?.sellPricePerArea | localNumber }}
              </div>
              <div
                class="column label-color flexB-60"
                *ngIf="
                  transaction?.salePrice &&
                  transaction?.bomaSoldArea &&
                  typeOpt === 2
                "
              >
                {{ "transaction.bomaprice" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="
                  transaction?.salePrice &&
                  transaction?.bomaSoldArea &&
                  typeOpt === 2
                "
              >
                {{
                  transaction?.salePrice / transaction?.bomaSoldArea
                    | localNumber
                }}
              </div>

              <div
                class="column label-color flexB-60"
                *ngIf="transaction?.bomaSoldArea && typeOpt === 2"
              >
                {{ "transaction.bomaSoldArea" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.bomaSoldArea && typeOpt === 2"
              >
                {{ transaction?.bomaSoldArea | localNumber }}
              </div>
            </div>
            <div
              *ngIf="transaction?.type != 'LEASE'"
              class="col-md-12 key-facts mt-2"
            >
              <span>{{ "transaction.capRateAnalysis" | translate }}</span>
            </div>
            <div
              *ngIf="transaction?.type != 'LEASE'"
              class="d-flex key-facts-columns border-table"
            >
              <div class="column label-color" *ngIf="transaction?.capRate">
                {{ "transaction.capRateStbilized" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.capRate">
                {{ transaction?.capRate | localNumber }} %
              </div>
              <div class="column label-color" *ngIf="transaction?.yearCapRate">
                {{ "transaction.capRateYear" | translate }}
              </div>
              <div class="column label-color2" *ngIf="transaction?.yearCapRate">
                {{ transaction?.yearCapRate | localNumber }} %
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.brazilianCapRate"
              >
                {{ "transaction.capRateBrazil" | translate }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.brazilianCapRate"
              >
                {{ transaction?.brazilianCapRate | localNumber }} %
              </div>
            </div>
            <div *ngIf="validateBrokerList" class="col-md-12 key-facts mt-2">
              <span>{{ "transaction.brokerDetail" | translate }}</span>
            </div>
            <div
              *ngIf="validateBrokerList"
              class="d-flex key-facts-columns border-table broker"
              id="broker"
            >
              <div
                class="column label-color"
                *ngIf="transaction?.broker1Company || transaction?.broker1User"
              >
                {{
                  transaction?.type == "LEASE"
                    ? ("transaction.landlordFirm" | translate)
                    : ("transaction.buyerFirm" | translate)
                }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.broker1Company || transaction?.broker1User"
              >
                {{
                  transaction?.broker1Company
                    ? transaction?.broker1Company
                    : transaction?.broker1User?.company.name
                }}
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.broker1User || transaction?.broker1Name"
              >
                {{
                  transaction?.type == "LEASE"
                    ? ("transaction.landlordName" | translate)
                    : ("transaction.buyerName" | translate)
                }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.broker1User || transaction?.broker1Name"
              >
                {{
                  transaction?.broker1Name
                    ? transaction?.broker1Name
                    : transaction?.broker1User?.firstNameAndLastName
                }}
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.broker1User || transaction?.broker1Email"
              >
                {{
                  transaction?.type == "LEASE"
                    ? ("transaction.landlordEmail" | translate)
                    : ("transaction.buyerEmail" | translate)
                }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.broker1User || transaction?.broker1Email"
              >
                {{
                  transaction?.broker1Email
                    ? transaction?.broker1Email
                    : transaction?.broker1User.email
                }}
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.broker2User || transaction?.broker2Company"
              >
                {{
                  transaction?.type == "LEASE"
                    ? ("transaction.tenantFirm" | translate)
                    : ("transaction.sellerFirm" | translate)
                }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.broker2User || transaction?.broker2Company"
              >
                {{
                  transaction?.broker2Company
                    ? transaction?.broker2Company
                    : transaction?.broker2User.company.name
                }}
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.broker2User || transaction?.broker2Name"
              >
                {{
                  transaction?.type == "LEASE"
                    ? ("transaction.tenantName" | translate)
                    : ("transaction.sellerName" | translate)
                }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.broker2User || transaction?.broker2Name"
              >
                {{
                  transaction?.broker2Name
                    ? transaction?.broker2Name
                    : transaction?.broker2User.firstNameAndLastName
                }}
              </div>
              <div
                class="column label-color"
                *ngIf="transaction?.broker2User || transaction?.broker2Email"
              >
                {{
                  transaction?.type == "LEASE"
                    ? ("transaction.tenantEmail" | translate)
                    : ("transaction.sellerEmail" | translate)
                }}
              </div>
              <div
                class="column label-color2"
                *ngIf="transaction?.broker2User || transaction?.broker2Email"
              >
                {{
                  transaction?.broker2Email
                    ? transaction?.broker2Email
                    : transaction?.broker2User.email
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-2 p-0">
          <!--   <div class="col-md-12" style="text-align: center;">
            <span style="font-size: 18px;
    font-weight: 600;" class="col-md-12">{{ "transaction.property" | translate }}:
              <a class="link" (click)="goToProperty()">{{
                property?.title | langSelector
                }}</a></span>
          </div> -->

          <!--  <div class="key-columns mt-2 p-0">
            <div class="col-md-6">
              <div class="col-md-12 key-facts mt-2">
                <span>{{ "transaction.propertyKeyFacts" | translate }}</span>
              </div>
              <div class="d-flex key-facts-columns border-table">
                <div
                  class="column label-color"
                  *ngIf="property?.classType?.name"
                >
                  {{ "detail.class" | translate }}
                </div>
                <div
                  class="column label-color2"
                  *ngIf="property?.classType?.name"
                >
                  {{ property?.classType?.name }}
                </div>
                <div
                  class="column label-color"
                  *ngIf="transaction?.building?.city?.countryState?.name"
                >
                  {{ "detail.state" | translate }}
                </div>
                <div
                  class="column label-color2"
                  *ngIf="transaction?.building?.city?.countryState?.name"
                >
                  {{
                    transaction?.building?.city?.countryState?.name
                      | langSelector
                  }}
                </div>
                <div class="column label-color" *ngIf="property?.postalCode">
                  {{ "detail.postalCode" | translate }}
                </div>
                <div class="column label-color2" *ngIf="property?.postalCode">
                  {{ property?.postalCode }}
                </div>
                <div class="column label-color" *ngIf="property?.region?.name">
                  {{
                    platformId == "br"
                      ? ("detail.region" | translate)
                      : ("detail.subMarket" | translate)
                  }}
                </div>
                <div class="column label-color2" *ngIf="property?.region?.name">
                  {{
                    platformId == "br"
                      ? (property?.region?.name | langSelector)
                      : property?.subMarket
                  }}
                </div>
                <div *ngIf="property?.latitude" class="column label-color">
                  {{ "detail.latLong" | translate }}
                </div>
                <div *ngIf="property?.latitude" class="column label-color2">
                  {{ property?.latitude }}/{{ property?.longitude }}
                </div>
                <div
                  class="column label-color"
                  *ngIf="property?.industrialStatus?.name"
                >
                  {{ "detail.status" | translate }}
                </div>

                <div
                  class="column label-color2"
                  *ngIf="property?.industrialStatus?.name"
                >
                  {{ property?.industrialStatus?.name | langSelector }}
                </div>
                <div
                  *ngIf="property?.buildingSubType"
                  class="column label-color"
                >
                  {{ "detail.properySubtype" | translate }}
                </div>
                <div
                  *ngIf="property?.buildingSubType"
                  class="column label-color2"
                >
                  {{ property?.buildingSubType?.name | langSelector }}
                </div>
                <div
                  *ngIf="property?.tenancy?.name"
                  class="column label-color "
                >
                  {{ "detail.multiTenant" | translate }}
                </div>
                <div
                  *ngIf="property?.tenancy?.name"
                  class="column label-color2"
                >
                  {{ property?.tenancy.name | langSelector }}
                </div>
                <div *ngIf="property?.deliveredDate" class="column label-color">
                  {{ "detail.deliveryDate" | translate }}
                </div>
                <div
                  *ngIf="property?.deliveredDate"
                  class="column label-color2"
                >
                  {{
                    browserLang == "pt"
                      ? (property?.deliveredDate | date: "dd/MM/yyyy")
                      : (property?.deliveredDate | date: "MM/dd/yyyy")
                  }}
                </div>
                <div
                  *ngIf="property?.lastExpansionDeliveryDate"
                  class="column label-color"
                >
                  {{ "detail.ExpansionDeliveryDate" | translate }}
                </div>
                <div
                  *ngIf="property?.lastExpansionDeliveryDate"
                  class="column label-color2"
                >
                  {{
                    browserLang == "pt"
                      ? (property?.lastExpansionDeliveryDate
                        | date: "dd/MM/yyyy")
                      : (property?.lastExpansionDeliveryDate
                        | date: "MM/dd/yyyy")
                  }}
                </div>
                <div
                  class="column label-color"
                  *ngIf="property?.status && isAdmin"
                >
                  {{ "detail.recordStatus" | translate }}
                </div>

                <div
                  class="column label-color2"
                  *ngIf="property?.status && isAdmin"
                >
                  {{ property?.status }}
                </div>

                <div class="column label-color" *ngIf="property?.retrofitDate">
                  {{ "detail.retrofitDate" | translate }}
                </div>

                <div class="column label-color2" *ngIf="property?.retrofitDate">
                  {{ property?.retrofitDate }}
                </div>
                <div class="column label-color" *ngIf="property?.neighborhood">
                  {{ "detail.neighborhood" | translate }}
                </div>

                <div class="column label-color2" *ngIf="property?.neighborhood">
                  {{ property?.neighborhood }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="col-md-12 key-facts mt-2">
                <span>{{ "detail.areasMeasurements" | translate }}</span>
              </div>
              <div
                class="d-flex key-facts-columns border-table"
                id="measurement"
                [ngClass]="{
                  'show-less': !hiddeMoreFeatures
                }"
              >
                <div class="column label-color" *ngIf="property?.rentableArea">
                  {{
                    property?.buildingType.id == 2001
                      ? ("detail.privateTotalAreas" | translate)
                      : ("detail.totalArea" | translate)
                  }}
                </div>
                <div class="column label-color2" *ngIf="property?.rentableArea">
                  {{ property?.rentableArea | localNumber }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id == 2001 &&
                    property?.averageFloorPrivateArea
                  "
                  class="column label-color"
                >
                  {{ "detail.avgFloorPrivateArea" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id == 2001 &&
                    property?.averageFloorPrivateArea
                  "
                  class="column label-color2"
                >
                  {{ property?.averageFloorPrivateArea | localNumber }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id == 2001 &&
                    property?.privateAvailableArea
                  "
                  class="column label-color"
                >
                  {{ "detail.privateAvailableArea" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id == 2001 &&
                    property?.privateAvailableArea
                  "
                  class="column label-color2"
                >
                  {{ property?.privateAvailableArea | localNumber }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id == 2001 && property?.bomaTotalArea
                  "
                  class="column label-color"
                >
                  {{ "detail.bomaTotalArea" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id == 2001 && property?.bomaTotalArea
                  "
                  class="column label-color2"
                >
                  {{ property?.bomaTotalArea | localNumber }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id == 2001 &&
                    property?.averageFloorBomaArea
                  "
                  class="column label-color"
                >
                  {{ "detail.avgFloorBomaArea" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id == 2001 &&
                    property?.averageFloorBomaArea
                  "
                  class="column label-color2"
                >
                  {{ property?.averageFloorBomaArea | localNumber }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id == 2001 &&
                    property?.bomaAvailableArea
                  "
                  class="column label-color"
                >
                  {{ "detail.bomaAvailableArea" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id == 2001 &&
                    property?.bomaAvailableArea
                  "
                  class="column label-color2"
                >
                  {{ property?.bomaAvailableArea | localNumber }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id != 2001 && property?.landArea
                  "
                  class="column label-color"
                >
                  {{ "detail.landAread" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id != 2001 && property?.landArea
                  "
                  class="column label-color2"
                >
                  {{ property?.landArea | localNumber }}
                </div>
                <div
                  *ngIf="property?.drivingDistance"
                  class="column label-color"
                >
                  {{ "detail.drivingDistance" | translate }}
                </div>
                <div
                  *ngIf="property?.drivingDistance"
                  class="column label-color2"
                >
                  {{ property?.drivingDistance | localNumber }}
                </div>
                <div
                  *ngIf="
                    (property?.buildingType.id == 2001 ||
                      property?.buildingType.id == 9001) &&
                    property?.numberStories
                  "
                  class="column label-color"
                >
                  {{ "detail.stories" | translate }}
                </div>
                <div
                  *ngIf="
                    (property?.buildingType.id == 2001 ||
                      property?.buildingType.id == 9001) &&
                    property?.numberStories
                  "
                  class="column label-color2"
                >
                  {{ property?.numberStories }}
                </div>
                <div *ngIf="property?.warehouse" class="column label-color">
                  {{ "detail.warehouse" | translate }}
                </div>
                <div *ngIf="property?.warehouse" class="column label-color2">
                  {{ property?.warehouse }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id != 3001 && property?.ceilingHeight
                  "
                  class="column label-color"
                >
                  {{ "detail.ceilingHeight" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id != 3001 && property?.ceilingHeight
                  "
                  class="column label-color2"
                >
                  {{ property?.ceilingHeight | localNumber }}
                </div>
                <div *ngIf="property?.tickerSymbol" class="column label-color">
                  {{ "detail.tickerSymbol" | translate }}
                </div>
                <div *ngIf="property?.tickerSymbol" class="column label-color2">
                  {{ property?.tickerSymbol }}
                </div>
                <div
                  *ngIf="property?.fiiOwnershipPercentage"
                  class="column label-color"
                >
                  {{ "detail.fiiOwnership" | translate }}
                </div>
                <div
                  *ngIf="property?.fiiOwnershipPercentage"
                  class="column label-color2"
                >
                  {{ property?.fiiOwnershipPercentage | localNumber }}%
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id == 1001 && property?.floorLoad
                  "
                  class="column label-color"
                >
                  {{ "detail.floorLoad" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id == 1001 && property?.floorLoad
                  "
                  class="column label-color2"
                >
                  {{ property?.floorLoad }}
                </div>
                <div
                  *ngIf="property?.buildingType.id == 1001 && property?.docks"
                  class="column label-color"
                >
                  {{ "detail.numberOfDocks" | translate }}
                </div>
                <div
                  *ngIf="property?.buildingType.id == 1001 && property?.docks"
                  class="column label-color2"
                >
                  {{ property?.docks }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id == 3001 && property?.majorTenants
                  "
                  class="column label-color"
                >
                  {{ "detail.majorTenants" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id == 3001 && property?.majorTenants
                  "
                  class="column label-color2"
                >
                  {{ property?.majorTenants }}
                </div>
                <div
                  *ngIf="
                    (property?.buildingType.id == 3001 ||
                      property?.buildingType.id == 9001) &&
                    property?.numberOfTenants
                  "
                  class="column label-color"
                >
                  {{ "detail.numberOfTenants" | translate }}
                </div>
                <div
                  *ngIf="
                    (property?.buildingType.id == 3001 ||
                      property?.buildingType.id == 9001) &&
                    property?.numberOfTenants
                  "
                  class="column label-color2"
                >
                  {{ property?.numberOfTenants }}
                </div>

                <div
                  class="column label-color"
                  *ngIf="property?.parkingType?.name"
                >
                  {{ "detail.parkingType" | translate }}
                </div>
                <div
                  class="column label-color2"
                  *ngIf="property?.parkingType?.name"
                >
                  {{ property?.parkingType?.name | langSelector }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id == 2001 &&
                    property?.industrialParkingSpaces
                  "
                  class="column label-color"
                >
                  {{ "detail.truckParkingSpaces" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id == 2001 &&
                    property?.industrialParkingSpaces
                  "
                  class="column label-color2"
                >
                  {{ property?.industrialParkingSpaces }}
                </div>
                <div
                  *ngIf="property?.carParkingSpaces"
                  class="column label-color "
                >
                  {{ "detail.carParkingSpaces" | translate }}
                </div>
                <div
                  *ngIf="property?.carParkingSpaces"
                  class="column label-color2"
                >
                  {{ property?.carParkingSpaces }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id != 1001 && property?.parkingSpaces
                  "
                  class="column label-color"
                >
                  {{ "detail.parkingSpaces" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.buildingType.id != 1001 && property?.parkingSpaces
                  "
                  class="column label-color2"
                >
                  {{ property?.parkingSpaces }}
                </div>
                <div *ngIf="property?.numUnits" class="column label-color">
                  {{ "detail.numUnits" | translate }}
                </div>
                <div *ngIf="property?.numUnits" class="column label-color2">
                  {{ property?.numUnits }}
                </div>
                <div *ngIf="property?.rentalType" class="column label-color">
                  {{ "detail.rentalType" | translate }}
                </div>
                <div *ngIf="property?.rentalType" class="column label-color2">
                  {{ property?.rentalType }}
                </div>
                <div *ngIf="property?.retailStories" class="column label-color">
                  {{ "detail.retailStories" | translate }}
                </div>
                <div
                  *ngIf="property?.retailStories"
                  class="column label-color2"
                >
                  {{ property?.retailStories }}
                </div>
                <div
                  *ngIf="property?.apartmentStories"
                  class="column label-color"
                >
                  {{ "detail.apartmentStories" | translate }}
                </div>
                <div
                  *ngIf="property?.apartmentStories"
                  class="column label-color2"
                >
                  {{ property?.apartmentStories }}
                </div>
                <div
                  *ngIf="property?.parkingStories"
                  class="column label-color"
                >
                  {{ "detail.parkingStories" | translate }}
                </div>
                <div
                  *ngIf="property?.parkingStories"
                  class="column label-color2"
                >
                  {{ property?.parkingStories }}
                </div>
                <div
                  *ngIf="property?.furnishing?.displayName"
                  class="column label-color"
                >
                  {{ "detail.furnishing" | translate }}
                </div>
                <div
                  *ngIf="property?.furnishing?.displayName"
                  class="column label-color2"
                >
                  {{ property?.furnishing?.displayName | langSelector }}
                </div>

                <div
                  *ngIf="property?.mixedUse || property?.mixedUse == false"
                  class="column label-color"
                >
                  {{ "detail.mixedUse" | translate }}
                </div>
                <div
                  *ngIf="property?.mixedUse || property?.mixedUse == false"
                  class="column label-color2"
                >
                  {{
                    property?.mixedUse
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.managerOnSite || property?.managerOnSite == false
                  "
                  class="column label-color"
                >
                  {{ "detail.managerOnSite" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.managerOnSite || property?.managerOnSite == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.managerOnSite
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.securityAccess ||
                    property?.securityAccess == false
                  "
                  class="column label-color"
                >
                  {{ "detail.securityAccess" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.securityAccess ||
                    property?.securityAccess == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.securityAccess
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>

                <div
                  *ngIf="
                    property?.hasElevators || property?.hasElevators == false
                  "
                  class="column label-color hasElevators"
                >
                  {{ "detail.elevators" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.hasElevators || property?.hasElevators == false
                  "
                  class="column label-color2 hasElevators"
                >
                  {{
                    property?.hasElevators
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>

                <div
                  *ngIf="
                    property?.airConditioning ||
                    property?.airConditioning == false
                  "
                  class="column label-color airConditioning"
                >
                  {{ "detail.airConditioning" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.airConditioning ||
                    property?.airConditioning == false
                  "
                  class="column label-color2 airConditioning"
                >
                  {{
                    property?.airConditioning
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>

                <div
                  *ngIf="
                    property?.fitnessCenter || property?.fitnessCenter == false
                  "
                  class="column label-color fitnessCenter"
                >
                  {{ "detail.FitnessCenter" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.fitnessCenter || property?.fitnessCenter == false
                  "
                  class="column label-color2 fitnessCenter"
                >
                  {{
                    property?.fitnessCenter
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>

                <div
                  *ngIf="property?.pool || property?.pool == false"
                  class="column label-color pool"
                >
                  {{ "detail.pool" | translate }}
                </div>
                <div
                  *ngIf="property?.pool || property?.pool == false"
                  class="column label-color2 pool"
                >
                  {{
                    property?.pool
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>

                <div
                  *ngIf="
                    property?.businessCenter ||
                    property?.businessCenter == false
                  "
                  class="column label-color"
                >
                  {{ "detail.businessCenter" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.businessCenter ||
                    property?.businessCenter == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.businessCenter
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="property?.clubhouse || property?.clubhouse == false"
                  class="column label-color"
                >
                  {{ "detail.Clubhouse" | translate }}
                </div>
                <div
                  *ngIf="property?.clubhouse || property?.clubhouse == false"
                  class="column label-color2"
                >
                  {{
                    property?.clubhouse
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.packageCenter || property?.packageCenter == false
                  "
                  class="column label-color"
                >
                  {{ "detail.packageCenter" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.packageCenter || property?.packageCenter == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.packageCenter
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.wifiInCommonAreas ||
                    property?.wifiInCommonAreas == false
                  "
                  class="column label-color"
                >
                  {{ "detail.wiFiInCommonAreas" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.wifiInCommonAreas ||
                    property?.wifiInCommonAreas == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.wifiInCommonAreas
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.retailOnSite || property?.retailOnSite == false
                  "
                  class="column label-color"
                >
                  {{ "detail.retailOnSite" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.retailOnSite || property?.retailOnSite == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.retailOnSite
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.onlineResidentServices ||
                    property?.onlineResidentServices == false
                  "
                  class="column label-color"
                >
                  {{ "detail.onlineResidentServices" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.onlineResidentServices ||
                    property?.onlineResidentServices == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.retailOnSite
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>

                <div
                  *ngIf="property?.dogPark || property?.dogPark == false"
                  class="column label-color dogPark"
                >
                  {{ "detail.dogPark" | translate }}
                </div>
                <div
                  *ngIf="property?.dogPark || property?.dogPark == false"
                  class="column label-color2 dogPark"
                >
                  {{
                    property?.dogPark
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>

                <div
                  *ngIf="property?.playground || property?.playground == false"
                  class="column label-color playground"
                >
                  {{ "detail.playground" | translate }}
                </div>
                <div
                  *ngIf="property?.playground || property?.playground == false"
                  class="column label-color2 playground"
                >
                  {{
                    property?.playground
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>

                <div
                  *ngIf="
                    property?.hoursMaintenance24 ||
                    property?.hoursMaintenance24 == false
                  "
                  class="column label-color"
                >
                  {{ "detail.24HourMaintenance" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.hoursMaintenance24 ||
                    property?.hoursMaintenance24 == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.hoursMaintenance24
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="property?.bikeRental || property?.bikeRental == false"
                  class="column label-color"
                >
                  {{ "detail.bikeRental" | translate }}
                </div>
                <div
                  *ngIf="property?.bikeRental || property?.bikeRental == false"
                  class="column label-color2"
                >
                  {{
                    property?.bikeRental
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="property?.dogWalker || property?.dogWalker == false"
                  class="column label-color"
                >
                  {{ "detail.dogWalker" | translate }}
                </div>
                <div
                  *ngIf="property?.dogWalker || property?.dogWalker == false"
                  class="column label-color2"
                >
                  {{
                    property?.dogWalker
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.sharedKitchen || property?.sharedKitchen == false
                  "
                  class="column label-color"
                >
                  {{ "detail.sharedKitchen" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.sharedKitchen || property?.sharedKitchen == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.sharedKitchen
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.sharedLaundry || property?.sharedLaundry == false
                  "
                  class="column label-color"
                >
                  {{ "detail.sharedLaundry" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.sharedLaundry || property?.sharedLaundry == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.sharedLaundry
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.rooftopLounge || property?.rooftopLounge == false
                  "
                  class="column label-color"
                >
                  {{ "detail.rooftopLounge" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.rooftopLounge || property?.rooftopLounge == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.rooftopLounge
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>

                <div
                  *ngIf="property?.partyRoom || property?.partyRoom == false"
                  class="column label-color"
                >
                  {{ "detail.partyRoom" | translate }}
                </div>
                <div
                  *ngIf="property?.partyRoom || property?.partyRoom == false"
                  class="column label-color2"
                >
                  {{
                    property?.partyRoom
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="property?.dishwasher || property?.dishwasher == false"
                  class="column label-color"
                >
                  {{ "detail.dishwasher" | translate }}
                </div>
                <div
                  *ngIf="property?.dishwasher || property?.dishwasher == false"
                  class="column label-color2"
                >
                  {{
                    property?.dishwasher
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="property?.disposal || property?.disposal == false"
                  class="column label-color"
                >
                  {{ "detail.disposal" | translate }}
                </div>
                <div
                  *ngIf="property?.disposal || property?.disposal == false"
                  class="column label-color2"
                >
                  {{
                    property?.disposal
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.washerDryerIncluded ||
                    property?.washerDryerIncluded == false
                  "
                  class="column label-color"
                >
                  {{ "detail.WasherDryerIncluded" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.washerDryerIncluded ||
                    property?.washerDryerIncluded == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.washerDryerIncluded
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.washerDryerHookup ||
                    property?.washerDryerHookup == false
                  "
                  class="column label-color"
                >
                  {{ "detail.WasherDryerHookup" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.washerDryerHookup ||
                    property?.washerDryerHookup == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.washerDryerHookup
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.walkInClosets || property?.walkInClosets == false
                  "
                  class="column label-color"
                >
                  {{ "detail.WalkInClosets" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.walkInClosets || property?.walkInClosets == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.walkInClosets
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.graniteCountertops ||
                    property?.graniteCountertops == false
                  "
                  class="column label-color"
                >
                  {{ "detail.graniteCountertops" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.graniteCountertops ||
                    property?.graniteCountertops == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.graniteCountertops
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.highSpeedInternetAccess ||
                    property?.highSpeedInternetAccess == false
                  "
                  class="column label-color"
                >
                  {{ "detail.highSpeedInternetAccess" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.highSpeedInternetAccess ||
                    property?.highSpeedInternetAccess == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.highSpeedInternetAccess
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="property?.wifi || property?.wifi == false"
                  class="column label-color"
                >
                  {{ "detail.WiFi" | translate }}
                </div>
                <div
                  *ngIf="property?.wifi || property?.wifi == false"
                  class="column label-color2"
                >
                  {{
                    property?.wifi
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.hardwoodFloors ||
                    property?.hardwoodFloors == false
                  "
                  class="column label-color"
                >
                  {{ "detail.hardwoodFloors" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.hardwoodFloors ||
                    property?.wifhardwoodFloorsi == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.hardwoodFloors
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.stainlessSteelAppliances ||
                    property?.stainlessSteelAppliances == false
                  "
                  class="column label-color"
                >
                  {{ "detail.stainlessSteelAppliances" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.stainlessSteelAppliances ||
                    property?.stainlessSteelAppliances == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.stainlessSteelAppliances
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>

                <div
                  *ngIf="
                    property?.ceilingFans || property?.ceilingFans == false
                  "
                  class="column label-color ceilingFans"
                >
                  {{ "detail.ceilingFans" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.ceilingFans || property?.ceilingFans == false
                  "
                  class="column label-color2 ceilingFans"
                >
                  {{
                    property?.ceilingFans
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>

                <div
                  *ngIf="
                    property?.privatePatio || property?.privatePatio == false
                  "
                  class="column label-color"
                >
                  {{ "detail.privatePatio" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.privatePatio || property?.privatePatio == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.privatePatio
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.cleaningServices ||
                    property?.cleaningServices == false
                  "
                  class="column label-color"
                >
                  {{ "detail.cleaningServices" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.cleaningServices ||
                    property?.cleaningServices == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.cleaningServices
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>

                <div
                  *ngIf="
                    property?.petFriendly || property?.petFriendly == false
                  "
                  class="column label-color petFriendly"
                >
                  {{ "detail.petFriendly" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.petFriendly || property?.petFriendly == false
                  "
                  class="column label-color2 petFriendly"
                >
                  {{
                    property?.petFriendly
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.bicycleRack || property?.bicycleRack == false
                  "
                  class="column label-color"
                >
                  {{ "detail.bicycleRack" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.bicycleRack || property?.bicycleRack == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.bicycleRack
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.blockConstruction ||
                    property?.blockConstruction == false
                  "
                  class="column label-color "
                >
                  {{ "detail.blockConstruction" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.blockConstruction ||
                    property?.blockConstruction == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.blockConstruction
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.steelConstruction ||
                    property?.steelConstruction == false
                  "
                  class="column label-color "
                >
                  {{ "detail.steelConstruction" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.steelConstruction ||
                    property?.steelConstruction == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.steelConstruction
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>
                <div
                  *ngIf="
                    property?.blockAndSteelConstruction ||
                    property?.blockAndSteelConstruction == false
                  "
                  class="column label-color "
                >
                  {{ "detail.blockAndSteelConstruction" | translate }}
                </div>
                <div
                  *ngIf="
                    property?.blockAndSteelConstruction ||
                    property?.blockAndSteelConstruction == false
                  "
                  class="column label-color2"
                >
                  {{
                    property?.blockAndSteelConstruction
                      ? ("detail.yes" | translate)
                      : ("detail.no" | translate)
                  }}
                </div>

                <div
                  *ngIf="property?.industrialParkingSpaces"
                  class="column label-color "
                >
                  {{ "detail.industrialParkingSpaces" | translate }}
                </div>
                <div
                  *ngIf="property?.industrialParkingSpaces"
                  class="column label-color2"
                >
                  {{ property?.industrialParkingSpaces }}
                </div>
                <div *ngIf="property?.baySize" class="column label-color ">
                  {{ "detail.baySize" | translate }}
                </div>
                <div *ngIf="property?.baySize" class="column label-color2">
                  {{ property?.baySize }}
                </div>
                <div *ngIf="property?.dockRatio" class="column label-color ">
                  {{ "detail.dockRatio" | translate }}
                </div>
                <div *ngIf="property?.dockRatio" class="column label-color2">
                  {{ property?.dockRatio }}
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="col-md-4">
        <aside>
          <div *ngIf="listingCards.length > 0">
            <ngx-slick-carousel #slickListing>
              <div ngxSlickItem *ngFor="let listing of listingCards">
                <featured-listing-card
                  class="slide"
                  [hideLogo]="true"
                  [listing]="listing"
                  [latamCountry]="null"
                ></featured-listing-card>
              </div>
            </ngx-slick-carousel>
            <button
              *ngIf="listingCards.length > 1"
              (click)="slickPrev(slickListing)"
              mat-basic-button
              #videoPrev
              aria-label="left arrow"
              class="news-leftLs btnCard chevron_left-listing"
            >
              <mat-icon>chevron_left</mat-icon>
            </button>
            <button
              *ngIf="listingCards.length > 1"
              (click)="slickNext(slickListing)"
              mat-basic-button
              #videoNext
              aria-label="right arrow"
              class="news-rightLs btnCard chevron_right-listing"
            >
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
          <div
            id="findBannerImage"
            (click)="openBannerImage()"
            *ngIf="listingCards.length == 0"
          >
            <img class="banner-img" [src]="findBannerImage()" alt="" />
          </div>
          <div *ngIf="articles && articles.content.length > 0">
            <ngx-slick-carousel #slickResource>
              <div
                ngxSlickItem
                *ngFor="let article of articles.content"
                class="slide"
              >
                <!--   <div style="text-align: center;">
                <span class="title">{{article.headline | langSelector }}</span>
              </div> -->
                <div
                  class="mat-elevation-z8 mt-2 resource"
                  style="cursor: pointer;"
                  (click)="openNews(article.id, article)"
                >
                  <img
                    class="w-100 img-topic aspect-4-3"
                    alt="test"
                    title="test"
                    [src]="
                      article?.headerImage?.fullPath
                        ? headerImageNews(article?.headerImage?.fullPath)
                        : ''
                    "
                    default="assets/img/defaultImage.png"
                  />
                  <div class="m-2 mt-3 pb-2">
                    <span style="font-weight: 700; color: #515050;"></span>
                    {{ article.headline | langSelector }}
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
            <button
              *ngIf="articles.content.length > 1"
              [ngClass]="{
                'chevron_left-resource-banner': listingCards.length == 0
              }"
              (click)="slickPrev(slickResource)"
              mat-basic-button
              #videoPrev
              aria-label="left arrow"
              class="news-leftLs btnCard chevron_left-resource"
            >
              <mat-icon>chevron_left</mat-icon>
            </button>
            <button
              *ngIf="articles.content.length > 1"
              [ngClass]="{
                'chevron_right-resource-banner': listingCards.length == 0
              }"
              (click)="slickNext(slickResource)"
              mat-basic-button
              #videoNext
              aria-label="right arrow"
              class="news-rightLs btnCard chevron_right-resource"
            >
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
          <div
            id="findSpotResourceBanner"
            (click)="openSpotResourceBannerLink()"
            *ngIf="articles && articles.content.length == 0"
          >
            <img class="banner-img" [src]="findSpotResourceBanner()" alt="" />
          </div>
        </aside>
      </div>
    </div>
    <div class="col-md-12 mt-5">
      <div class="col-md-12">
        <span class="title col-md-12 ">{{
          "detail.transactionsAnalytics" | translate
        }}</span>
      </div>
      <div class="col-md-8" style="margin: 30 auto;">
        <mat-button-toggle-group
          class="toggle-group w-100 toggle-options"
          appearance="legacy"
          name="fontStyle"
          aria-label="Font Style"
        >
          <mat-button-toggle
            [ngClass]="{ 'active-toggle': typeChar === 1 }"
            (click)="
              transaction?.type == 'SALE' ? setCharSale(1) : setCharLease(1)
            "
            class="toogle-btn"
          >
            {{
              transaction?.type == "SALE"
                ? ("transaction.salePrice" | translate)
                : ("detail.currentProperty" | translate)
            }}</mat-button-toggle
          >
          <mat-button-toggle
            [ngClass]="{ 'active-toggle': typeChar === 2 }"
            (click)="
              transaction?.type == 'SALE' ? setCharSale(2) : setCharLease(2)
            "
            class="toogle-btn"
          >
            {{
              transaction?.type == "SALE"
                ? ("transaction.capRate" | translate)
                : (transaction?.building?.city?.countryState?.name
                  | langSelector)
            }}</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
      <div class="col-md-12 mt-2" id="chart">
        <apx-chart
          *ngIf="transaction?.type == 'SALE'"
          id="apex-chart"
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [xaxis]="chartOptions.xaxis"
          [yaxis]="chartOptions.yaxis"
          [tooltip]="chartOptions.tooltip"
        ></apx-chart>
        <apx-chart
          *ngIf="transaction?.type == 'LEASE'"
          [series]="chartOptionsLease.series"
          [chart]="chartOptionsLease.chart"
          [yaxis]="chartOptionsLease.xaxis"
          [yaxis]="chartOptionsLease.yaxis"
          [tooltip]="chartOptionsLease.tooltip"
          [markers]="chartOptionsLease.markers"
          [fill]="chartOptionsLease.fill"
          [colors]="chartOptionsLease.colors"
        ></apx-chart>
      </div>
    </div>
    <div class="col-md-12 mt-5">
      <p
        translate="products.{{
          currentCode
        }}.products.features-card.marketAnalytics.findMore"
        class="find-more siila-grey-dark"
      ></p>
      <div class="market-analytics-card mt-5">
        <ng-container *ngFor="let card of marketCardList">
          <app-info-card
            (click)="openInfoCard(card)"
            [icon]="card.src"
            [iconAltTitle]="card.iconAltTitle"
            [title]="card.title | langSelector"
            [text]="card.text | langSelector"
            [actionTxt]="card.actionText | langSelector"
            [background]="card.background"
          ></app-info-card>
        </ng-container>
      </div>
    </div>
  </div>
</div>
