import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { ImageService } from "../../../core";
import {
  kmlCommercial,
  kmlIndustrial,
  kmlOffice,
  latLog
} from "../../utils/params";
import { MatDialog } from "@angular/material/dialog";
import { BreakpointObserver } from "@angular/cdk/layout";
import { ImageGalleryComponent } from "../image-gallery/image-gallery.component";
@Component({
  selector: "app-show-images",
  templateUrl: "./show-images.component.html",
  styleUrls: ["./show-images.component.scss"]
})
export class ShowImagesComponent implements OnInit, OnChanges {
  @Input() photos = [];
  @Input() latitude;
  @Input() longitude;
  @Input() buildingTypeId;
  zoom: number = 12;
  mapTypeIds = "roadmap";
  showKml = false;
  kmsList = [];
  dialogRef: any;
  isMobile: boolean = false;

  get photoValidation() {
    return this.photos.length > 1;
  }

  constructor(
    private imageService: ImageService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver
      .observe(["(max-width: 450px)"])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }
  ngOnChanges() {
    if (this.buildingTypeId) {
      this.setKmls();
    }
  }
  ngOnInit(): void {}

  setKmls() {
    switch (this.buildingTypeId) {
      case 3001:
        this.kmsList = kmlCommercial;
        break;
      case 1001:
        this.kmsList = kmlIndustrial;
        break;
      case 2001:
        this.kmsList = kmlOffice;
        break;

      default:
        break;
    }
  }

  getImage(id) {
    return this.imageService.mediumLargeThumbnail(id);
  }

  toggleKml(): void {
    this.showKml = !this.showKml;
    setTimeout(() => {
      this.setLatLon();
    }, 200);
  }

  setLatLon() {
    if (this.showKml) {
      this.latitude += latLog;
      this.longitude += latLog;
      this.zoom = 12;
    } else {
      this.latitude -= latLog;
      this.longitude -= latLog;
      this.zoom = 17;
    }
  }

  showMap() {
    this.mapTypeIds = "roadmap";
  }

  showSatellite() {
    this.mapTypeIds = "satellite";
  }

  openPhotos() {
    this.dialogRef = this.dialog.open(ImageGalleryComponent, {
      height: this.isMobile ? "100%" : "auto",
      width: this.isMobile ? "100%" : "44%",
      maxWidth: "none",
      data: this.photos,
      panelClass: "custom"
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }
}
