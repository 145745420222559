<div *ngIf="!isMobile; else real" class="home-card-white mat-elevation-z15">
  <div class="home-analytic">
    <div class="home-analytic-info">
      <h1
        class="title-gnrl siila-blue-color uppercase"
        translate="products.{{
          currentCode
        }}.products.features-card.marketAnalytics.overline"
      ></h1>
      <h2
        class="home-sub-title-products siila-menu-color"
        translate="products.{{
          currentCode
        }}.products.features-card.marketAnalytics.description-title"
      ></h2>
      <p
        class="home-description-products siila-grey"
        translate="products.{{
          currentCode
        }}.products.features-card.marketAnalytics.description"
      ></p>
      <div>
        <button
          (click)="openTarted()"
          mat-flat-button
          color="accent"
          class="btn-main"
        >
          {{ "started" | translate }}
        </button>
      </div>
    </div>
    <div class="home-analytic-img">
      <img
        class="cursor"
        (click)="openTarted()"
        class="img-size"
        [src]="imgMarketAnalytics()"
        [alt]="imgMarketAnalyticsAlt()"
        [title]="imgMarketAnalyticsAlt()"
      />
    </div>
  </div>
  <div class="border-line-home"></div>
  <div class="home-analytic">
    <div class="home-spot-img">
      <img
        class="cursor"
        (click)="openSpot()"
        class="img-size-spot"
        [src]="imgHomeSpot()"
        [alt]="imgHomeSpotAlt()"
        [title]="imgHomeSpotAlt()"
      />
    </div>
    <div class="home-spot-info">
      <h1
        class="title-gnrl siila-blue-color uppercase"
        translate="products.{{
          currentCode
        }}.products.marketing-card.marketing-product.real"
      ></h1>
      <h2
        class="home-sub-title-products siila-menu-color"
        translate="products.{{
          currentCode
        }}.products.marketing-card.marketing-product.spot"
      ></h2>
      <p
        [ngStyle]="{
          'text-align': currentCode != 'co' ? 'inherit' : 'justify'
        }"
        class="home-description-products siila-grey"
        translate="products.{{
          currentCode
        }}.products.marketing-card.marketing-product.description"
      ></p>
      <div class="btn-more-state">
        <button
          *ngIf="currentCode != 'co'"
          (click)="openSpot()"
          mat-flat-button
          color="accent"
          class="btn-main"
        >
          {{ "more" | translate }}
        </button>
        <button
          *ngIf="currentCode == 'co'"
          (click)="openSpot('mx')"
          mat-flat-button
          color="accent"
          class="btn-main"
        >
          {{ "news.mexico" | translate }}
        </button>
        <button
          *ngIf="currentCode == 'co'"
          (click)="openSpot('br')"
          mat-flat-button
          color="accent"
          class="btn-main ml-2"
        >
          {{ "news.brasil" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="border-line-home"></div>
  <div class="all-btn">
    <button
      (click)="openProducts()"
      mat-flat-button
      color="accent"
      class="button-main uppercase"
    >
      {{ "all" | translate }}
    </button>
  </div>
</div>
<ng-template #real>
  <div class="home-card-white mat-elevation-z15">
    <div class="home-analytic">
      <div class="home-analytic-info">
        <h1
          class="title-gnrl siila-blue-color uppercase"
          translate="products.{{
            currentCode
          }}.products.features-card.marketAnalytics.overline"
        ></h1>
        <h2
          class="home-sub-title-products siila-menu-color"
          translate="products.{{
            currentCode
          }}.products.features-card.marketAnalytics.description-title"
        ></h2>
        <p
          class="home-description-products siila-grey"
          translate="products.{{
            currentCode
          }}.products.features-card.marketAnalytics.description"
        ></p>
      </div>
      <div class="home-analytic-img">
        <img
          class="cursor"
          (click)="openTarted()"
          class="img-size"
          [src]="imgMarketAnalytics()"
        />
      </div>

      <button
        (click)="openTarted()"
        mat-flat-button
        color="accent"
        class="btn-main"
      >
        {{ "started" | translate }}
      </button>
    </div>
    <div class="border-line-home"></div>
    <div class="home-analytic">
      <div class="home-spot-info">
        <h1
          class="title-gnrl siila-blue-color uppercase"
          translate="products.{{
            currentCode
          }}.products.marketing-card.marketing-product.real"
        ></h1>
        <h2
          class="home-sub-title-products siila-menu-color"
          translate="products.{{
            currentCode
          }}.products.marketing-card.marketing-product.spot"
        ></h2>
        <p
          class="home-description-products siila-grey"
          translate="products.{{
            currentCode
          }}.products.marketing-card.marketing-product.description"
        ></p>
        <div class="home-spot-img">
          <img
            class="cursor"
            (click)="openSpot()"
            class="img-size-spot"
            [src]="imgHomeSpot()"
          />
        </div>
        <button
          (click)="openSpot()"
          mat-flat-button
          color="accent"
          class="btn-main"
        >
          {{ "more-uni" | translate }}
        </button>
      </div>
    </div>
    <div class="border-line-home"></div>
    <div class="all-btn">
      <button
        (click)="openProducts()"
        mat-flat-button
        color="accent"
        class="button-main"
      >
        {{ "all" | translate }}
      </button>
    </div>
  </div>
</ng-template>
