import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { ProductItem } from "../../../../core/models/products.model";
import { DemoDialogService } from "../../../../core/services/dialog";
import { ContactDialogService } from "../../../../core/services/dialog/contact-dialog.service";
import { ProductService } from "../../../../core/services/products.service";
import { SiilaTranslationService } from "../../../../core/services/siila-translation.service";
import { I18nService } from "../../../services";
import { MetaTagService } from "../../../services/meta-tag.service";
import { action, page } from "../../../utils/params";
import { DemoDialogComponent } from "../../dialogs";
@Component({
  selector: "analytics",
  templateUrl: "./market-analytics.component.html",
  styleUrls: [
    "./market-analytics.component.scss",
    "../product-list/product-list.component.scss"
  ]
})
export class MarketAnalyticsComponent implements OnInit {
  translatePath: string;
  translateMarketInfo: string;
  analyticPath: string;
  marketCardTranslatePath =
    "products.br.products.features-card.marketAnalytics.card-list.";
  resourseTranslatePanth =
    "products.marketing-card.featuredResources.resources.card-list.";
  checkList = [];
  marketCardList = [];
  analyticCheck = [];
  featureResouces = [];
  products: ProductItem[];
  currentCode: string;
  responsiveSub: Subscription;
  browserLang: string = "en";
  action = action;
  routerSub: Subscription;
  tagId = null;
  marketAnalyticsAltTitle: string;
  mapicalt: string;
  propertyIcon: string;
  officeIcon: string;
  moneyIcon: string;
  accountIcon: string;
  strategyWorld: string;
  constructor(
    private productService: ProductService,
    private siilaTranslationService: SiilaTranslationService,
    private demo: DemoDialogService,
    private i18: I18nService,
    private metatagservice: MetaTagService,
    private route: ActivatedRoute
  ) {
    this.currentCode = environment.platformId;
    this.analyticPath = `analytics.${this.currentCode}.strategy.strategy-data.`;
    this.translatePath = `products.${this.currentCode}.products.features-card.marketAnalytics.check-list.`;
    this.translateMarketInfo = `analytics.${this.currentCode}.product-add`;
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    this.initData();
    if (this.currentCode == "mx") {
      this.tagId = 64;
    }

    if (this.currentCode == "br" || this.currentCode == "latam") {
      this.tagId = 60;
    }

    /* if (this.currentCode == "latam") {
      this.tagId = 68;
    } */
  }
  private initData() {
    this.checkList = [...Array(5).keys()].map(i => {
      return {
        icon: "assets/icons/check.svg",
        title: `${this.translatePath}check${i + 1}.title`,
        desc: `${this.translatePath}check${i + 1}.desc`
      };
    });

    this.analyticCheck = [...Array(7).keys()].map(i => {
      return {
        icon: "assets/icons/check.svg",
        title: `${this.analyticPath}data${i + 1}.title`,
        desc: `${this.analyticPath}data${i + 1}.desc`
      };
    });

    this.featureResouces = [
      {
        tags: ["tags.brazil", "tags.marketAnalitic"],
        background: "assets/img/products-list/Card1.png",
        title: `${this.resourseTranslatePanth}card1.title`,
        text: `${this.resourseTranslatePanth}card1.text`,
        actionText: `${this.resourseTranslatePanth}card1.action-text`
      },
      {
        tags: ["tags.brazil", "tags.marketAnalitic"],
        background: "assets/img/products-list/Card2.png",
        title: `${this.resourseTranslatePanth}card2.title`,
        text: `${this.resourseTranslatePanth}card2.text`,
        actionText: `${this.resourseTranslatePanth}card2.action-text`
      },
      {
        tags: ["tags.brazil", "tags.marketAnalitic"],
        background: "assets/img/products-list/Card3.png",
        title: `${this.resourseTranslatePanth}card3.title`,
        text: `${this.resourseTranslatePanth}card3.text`,
        actionText: `${this.resourseTranslatePanth}card3.action-text`
      }
    ];
  }
  ngOnInit(): void {
    this.marketAnalyticsAltTitle = this.siilaTranslationService.getImgSEOTranslation(
      "marketAnalytics.mainTitle"
    );
    this.mapicalt = this.siilaTranslationService.getImgSEOTranslation(
      "marketAnalytics.mapicalt"
    );
    this.propertyIcon = this.siilaTranslationService.getImgSEOTranslation(
      "marketAnalytics.propertyIcon"
    );
    this.officeIcon = this.siilaTranslationService.getImgSEOTranslation(
      "marketAnalytics.officeIcon"
    );
    this.moneyIcon = this.siilaTranslationService.getImgSEOTranslation(
      "marketAnalytics.moneyIcon"
    );
    this.accountIcon = this.siilaTranslationService.getImgSEOTranslation(
      "marketAnalytics.accountIcon"
    );
    this.strategyWorld = this.siilaTranslationService.getImgSEOTranslation(
      "marketAnalytics.strategyWorld"
    );
    this.routerSub = this.route.paramMap.subscribe(params => {
      this.metatagservice.setMarketAnalyticsMetaTags();
    });
    this.productService.getMarketAnalytics().subscribe(products => {
      this.products = products;
      this.marketCardList = products.map(item => {
        return {
          id: item.id,
          src: `${environment.cloudfrontURL}${item.iconPath}/360w/${item.iconFilename}`,
          iconAltTitle: this.findIconAltTitle(item),
          title: item.productShortName,
          text: item.menuItemDescription,
          actionText: item.menuItemActionButtonText
        };
      });
    });
  }

  findIconAltTitle = (item: any) => {
    let card = "imgSEO.marketAnalytics.cardicons.";
    if (item.id == 101) {
      card += "gross";
    } else if (item.id == 102) {
      card += "index";
    } else {
      card += "investorsurvey";
    }
    return this.siilaTranslationService.getTranslationFromJSONFiles(card);
  };

  ngOnDestroy(): void {
    if (this.routerSub) this.routerSub.unsubscribe();
  }

  openContactDialog(action: string) {
    this.demo.open(
      DemoDialogComponent,
      false,
      page.analitycs,
      action,
      "",
      this.i18.get(`analytics.${this.currentCode}.analytic-list.titleModal`)
    );
  }

  openCard(id: number, title: string) {
    window.open(
      `${environment.applicationURL}/products/${id}/${this.i18.getTranslation(
        title
      )}`,
      "_blank"
    );
  }

  imgAnalytics() {
    let img = "../../../../../assets/img/analytics-list/";
    if (this.currentCode == "mx" || this.currentCode == "co") {
      if (this.browserLang == "en") {
        img = img + "item-2-mockup SiiLA-EN-MX.png";
      } else {
        img = img + "item-2-mockup SiiLA-ES.png";
      }
    } else {
      if (this.browserLang == "en") {
        img = img + "item-2-mockup SiiLA-EN-BR.png";
      } else {
        img = img + "item-2-mockup SiiLA-PT.png";
      }
    }

    return img;
  }
}
